import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthorizationService } from './services/base/authorization.service';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./pages/authentication/authentication.module').then(m => m.AuthenticationModule),
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthorizationService],
    canActivateChild: [AuthorizationService],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/meldingen'
      },
      {
        path: 'meldingen',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: {
          permissions: [
            'can-assign-new-reports',
            'can-bulk-handle-reports',
            'can-copy-reports',
            'can-create-reports',
            'can-delete-reports',
            'can-edit-closed-reports',
            'can-forward-reports',
            'can-print-reports',
            'can-read-reports-from-all-organisations',
            'can-reject-reports',
            'can-reopen-closed-reports',
            'can-resolve-reports',
            'can-see-deleted-reports',
            'can-see-unassigned-reports',
            'can-update-reports',
            'can-view-reports',
          ]
        }
      },
      {
        path: 'selectie',
        loadChildren: () => import('./pages/search-reports/search-reports.module').then(m => m.SearchReportsModule),
        data: {
          permissions: [
            'can-view-report-search-page',
          ]
        }
      },
      {
        path: 'routering',
        loadChildren: () => import('./pages/routering/routering.module').then(m => m.RouteringModule),
        data: {
          permissions: [
            'config-can-manage-organisations',
            'can-create-api-token',
            'config-can-manage-departments',
            'config-can-manage-all-departments',
            'config-can-manage-roles',
            'config-can-manage-users',
            'config-can-manage-users-from-all-organisations',
            'config-can-manage-categories',
          ]
        }
      },
      {
        path: 'instellingen',
        loadChildren: () => import('./pages/configuration/configuration.module').then(m => m.ConfigurationModule),
        data: {
          permissions: [
            'config-can-manage-email-templates',
            'config-can-manage-standard-reactions',
            'config-can-manage-address-book',
            'config-can-manage-knowledge-base',
            'config-can-manage-faq',
            'config-can-manage-changelogs',
            'config-can-manage-work-areas',
            'config-can-manage-report-products',
            'config-can-manage-report-statuses',
            'config-can-manage-report-sources',
            'config-can-manage-report-status-codes',
            'config-can-manage-report-history',
            'config-can-manage-report-causer-types',
            'config-can-manage-reporter-types',
            'config-can-manage-reporter-contact-timeframes',
            'config-can-manage-company-options',
            'config-can-manage-unusual-incident-causes',
            'config-can-manage-unusual-incident-consequences',
            'can-manage-client-portal-user-agreement',
          ]
        }
      },
      {
        path: 'info',
        loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule),
        data: {
          permissions: [
            'companies.create',
            'companies.read',
            'companies.update',
            'companies.delete',
            'config-can-manage-reporters',
          ]
        }
      },
      {
        path: 'importeer-exporteer',
        loadChildren: () => import('./pages/import-export/import-export.module').then(m => m.ImportExportModule),
        data: {
          permissions: [
            'can-import-companies',
            'config-can-import-reporters',
            'can-export-reports-into-csv',
          ]
        }
      },
      {
        path: 'handleiding',
        loadChildren: () => import('./pages/knowledge-base/knowledge-base.module').then(m => m.KnowledgeBaseModule),
      },
      {
        path: 'kaart-weergave',
        loadChildren: () => import('./pages/map/map.module').then(m => m.MapModule),
        data: {
          permissions: [
            'can-view-map-page'
          ]
        }
      },
      {
        path: 'statistics',
        loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule),
        data: {
          permissions: [
            'can-read-statistics',
            'statistics.mkc-report.read',
          ]
        }
      },
      {
        path: 'aankondigingen',
        loadChildren: () => import('./pages/public-notifications/public-notifications.module').then(m => m.PublicNotificationsModule),
        data: {
          permissions: [
            'config-can-manage-public-notifications',
          ],
        }
      },
      {
        path: 'klantenportaal',
        loadChildren: () => import('./pages/client-portal-groups/client-portal-groups.module').then(m => m.ClientPortalGroupsModule),
        data: {
          permissions: [
            'can-manage-client-portal-users'
          ],
        }
      },
      {
        path: 'archivering',
        loadChildren: () => import('./pages/archive/archive/archive.module').then(m => m.ArchiveModule),
//        loadChildren: () => import('./pages/archive/archive.module').then(m => m.ArchiveModule),
        data: {
          permissions: [
            'can-manage-archive'
          ],
        }
      },
      {
        path: 'sent-emails',
        loadChildren: () => import('./pages/sent-emails/sent-emails.module').then(m => m.SentEmailsModule),
        data: {
          permissions: [],
        }
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/meldingen'
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
//    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

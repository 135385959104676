import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable()

export class ApiEndpointsService {

  private endpoints: { key: string, route: string }[] | [] = [
    {key: 'public.default-organisation', route: 'v1/public/default-organisation'},

    {key: 'auth.login', route: 'auth/login'},
    {key: 'auth.logout', route: 'auth/logout'},
    {key: 'auth.forgot-password', route: 'auth/forgot-password'},
    {key: 'auth.reset-password.validate', route: 'auth/reset-password/:token?email=:email'},
    {key: 'auth.reset-password', route: 'auth/reset-password'},
    {key: 'auth.change-password', route: 'auth/change-password'},
    {key: 'auth.complete-account', route: 'auth/complete-account/:id?expires=:expires&signature=:signature'},
    {key: 'auth.account', route: 'auth/account'},
    {key: 'auth.impersonate', route: 'auth/impersonate'},

    {key: 'filters.organisations', route: 'v1/filters/organisations'},
    {key: 'filters.organisations-for-active-user', route: 'v1/filters/organisations/for-active-user'},
    {key: 'filters.departments', route: 'v1/filters/departments'},
    {key: 'filters.organisation-departments', route: 'v1/filters/organisations/:id/departments'},
    {key: 'filters.organisation-users', route: 'v1/filters/organisations/:id/users'},
    {key: 'filters.organisation-department-users', route: 'v1/filters/organisations/:organisation_id/departments/:department_id/users'},
    {key: 'filters.assigned-organisation-municipalities', route: 'v1/filters/organisation/municipalities'},
    {key: 'filters.assigned-organisation-departments', route: 'v1/filters/organisation/departments'},
    {key: 'filters.assigned-organisation-users', route: 'v1/filters/organisation/users'},
    {key: 'filters.assigned-organisation-department-users', route: 'v1/filters/organisation/departments/:department_id/users'},
    {key: 'filters.organisations-with-departments', route: 'v1/filters/organisations/departments'},
    {key: 'filters.permissions', route: 'v1/filters/permissions'},
    {key: 'filters.permission-groups', route: 'v1/filters/permissions/groups'},
    {key: 'filters.roles', route: 'v1/filters/roles'},
    {key: 'filters.mail-types', route: 'v1/filters/mail-types'},
    {key: 'filters.provinces', route: 'v1/filters/provinces'},
    {key: 'filters.client-portal-available-provinces', route: 'v1/filters/client-portal-available-provinces'},
    {key: 'filters.client-portal-available-provinces-include', route: 'v1/filters/client-portal-available-provinces/:id'},
    {key: 'filters.available-municipalities', route: 'v1/filters/municipalities/available'},
    {key: 'filters.available-municipalities-departments', route: 'v1/filters/municipalities/available-for-departments/:id'},
    {key: 'filters.municipalities', route: 'v1/filters/municipalities'},
    {key: 'filters.municipalities-and-provinces', route: 'v1/filters/municipalities-and-provinces'},
    {key: 'filters.client-portal-available-municipalities', route: 'v1/filters/client-portal-available-municipalities'},
    {key: 'filters.client-portal-available-municipalities-include', route: 'v1/filters/client-portal-available-municipalities/:id'},
    {key: 'filters.cities', route: 'v1/filters/cities'},
    {key: 'filters.city', route: 'v1/filters/cities/:city_id'},
    {key: 'filters.report-numbers', route: 'v1/filters/report-numbers'},
    {key: 'filters.report-products', route: 'v1/filters/report-products'},
    {key: 'filters.report-statuses', route: 'v1/filters/report-statuses'},
    {key: 'filters.report-history-types', route: 'v1/filters/report-history-types'},
    {key: 'filters.report-causer-types', route: 'v1/filters/report-causer-types'},
    {key: 'filters.reporter-types', route: 'v1/filters/reporter-types'},
    {key: 'filters.reporter-contact-timeframes', route: 'v1/filters/reporter-contact-timeframes'},
    {key: 'filters.users', route: 'v1/filters/users'},
    {key: 'filters.risks', route: 'v1/filters/risks'},
    {key: 'filters.head-categories-types', route: 'v1/filters/head-categories-types'},
    {key: 'filters.head-categories', route: 'v1/filters/head-categories'},
    {key: 'filters.head-categories-for-surrounding', route: 'v1/filters/head-categories/surrounding/:id'},
    {key: 'filters.head-categories.main-categories', route: 'v1/filters/head-categories/:head_category_id/main-categories'},
    {
      key: 'filters.head-categories.main-categories-for-surrounding',
      route: 'v1/filters/head-categories/:head_category_id/main-categories/surrounding/:id'
    },
    {
      key: 'filters.head-categories.main-categories.sub-categories',
      route: 'v1/filters/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories'
    },
    {key: 'filters.main-categories-surroundings', route: 'v1/filters/main-categories-surroundings'},
    {key: 'filters.main-categories', route: 'v1/filters/main-categories'},
    {key: 'filters.report-sources', route: 'v1/filters/report-sources'},
    {key: 'filters.company-options', route: 'v1/filters/company-options'},
    {key: 'filters.companies', route: 'v1/filters/companies'},
    {key: 'filters.companies.search', route: 'v1/filters/companies/search'},
    {key: 'filters.companies-assigned.search', route: 'v1/filters/companies/assigned/search'},
    {key: 'filters.companies-with-types', route: 'v1/filters/companies/with-types'},
    {key: 'filters.report-status-codes', route: 'v1/filters/report-status-codes'},
    {key: 'filters.report-status-codes-for', route: 'v1/filters/report-status-codes/:for'},
    {key: 'filters.address-book', route: 'v1/filters/address-book'},
    {key: 'filters.standard-reactions', route: 'v1/filters/standard-reactions'},
    {key: 'filters.report-history', route: 'v1/filters/report-history'},
    {key: 'filters.address-book-types', route: 'v1/filters/address-book-types'},
    {key: 'filters.reporters', route: 'v1/filters/reporters'},
    {key: 'filters.unusual-incident-causes', route: 'v1/filters/unusual-incident-causes'},
    {key: 'filters.unusual-incident-consequences', route: 'v1/filters/unusual-incident-consequences'},
    {key: 'filters.knowledge-base.changelogs.types', route: 'v1/filters/knowledge-base/changelogs/types'},
    {key: 'filters.public-notification-types', route: 'v1/filters/public-notification-types'},
    {key: 'filters.content-visibility', route: 'v1/filters/content-visibility'},
    {key: 'filters.sent-emails.events', route: 'v1/filters/sent-emails/events'},
    {key: 'filters.sent-emails.log-levels', route: 'v1/filters/sent-emails/log-levels'},
    {key: 'filters.sent-emails.tags', route: 'v1/filters/sent-emails/tags'},

    {key: 'routering.organisations.index', route: 'v1/configuration/organisations'},
    {key: 'routering.organisations.create', route: 'v1/configuration/organisations'},
    {key: 'routering.organisations.read', route: 'v1/configuration/organisations/:id'},
    {key: 'routering.organisations.sort', route: 'v1/configuration/organisations/sort'},
    {key: 'routering.organisations.update', route: 'v1/configuration/organisations/:id'},
    {key: 'routering.organisations.delete', route: 'v1/configuration/organisations/:id'},
    {key: 'routering.organisations.restore', route: 'v1/configuration/organisations/:id/restore'},
    {key: 'routering.organisations.api-token', route: 'v1/configuration/organisations/api-token'},
    {key: 'routering.departments.index', route: 'v1/configuration/departments'},
    {key: 'routering.departments.create', route: 'v1/configuration/departments'},
    {key: 'routering.departments.read', route: 'v1/configuration/departments/:id'},
    {key: 'routering.departments.update', route: 'v1/configuration/departments/:id'},
    {key: 'routering.departments.delete', route: 'v1/configuration/departments/:id'},
    {key: 'routering.departments.restore', route: 'v1/configuration/departments/:id/restore'},
    {key: 'routering.roles.index', route: 'v1/configuration/roles'},
    {key: 'routering.roles.create', route: 'v1/configuration/roles'},
    {key: 'routering.roles.read', route: 'v1/configuration/roles/:id'},
    {key: 'routering.roles.update', route: 'v1/configuration/roles/:id'},
    {key: 'routering.roles.delete', route: 'v1/configuration/roles/:id'},
    {key: 'routering.users.index', route: 'v1/configuration/users'},
    {key: 'routering.users.create', route: 'v1/configuration/users'},
    {key: 'routering.users.read', route: 'v1/configuration/users/:id'},
    {key: 'routering.users.update', route: 'v1/configuration/users/:id'},
    {key: 'routering.users.delete', route: 'v1/configuration/users/:id'},
    {key: 'routering.users.restore', route: 'v1/configuration/users/:id/restore'},
    {key: 'routering.users.resend-invitation', route: 'v1/configuration/users/:id/resend-invitation'},
    {key: 'routering.head-categories.index', route: 'v1/configuration/head-categories'},
    {key: 'routering.head-categories.create', route: 'v1/configuration/head-categories'},
    {key: 'routering.head-categories.read', route: 'v1/configuration/head-categories/:id'},
    {key: 'routering.head-categories.update', route: 'v1/configuration/head-categories/:id'},
    {key: 'routering.head-categories.delete', route: 'v1/configuration/head-categories/:id'},
    {key: 'routering.head-categories.restore', route: 'v1/configuration/head-categories/:id/restore'},
    {key: 'routering.main-categories.index', route: 'v1/configuration/head-categories/:head_category_id/main-categories'},
    {key: 'routering.main-categories.create', route: 'v1/configuration/head-categories/:head_category_id/main-categories'},
    {key: 'routering.main-categories.read', route: 'v1/configuration/head-categories/:head_category_id/main-categories/:id'},
    {key: 'routering.main-categories.update', route: 'v1/configuration/head-categories/:head_category_id/main-categories/:id'},
    {key: 'routering.main-categories.delete', route: 'v1/configuration/head-categories/:head_category_id/main-categories/:id'},
    {key: 'routering.main-categories.restore', route: 'v1/configuration/head-categories/:head_category_id/main-categories/:id/restore'},
    {
      key: 'routering.sub-categories.index',
      route: 'v1/configuration/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories'
    },
    {
      key: 'routering.sub-categories.create',
      route: 'v1/configuration/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories'
    },
    {
      key: 'routering.sub-categories.read',
      route: 'v1/configuration/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories/:id'
    },
    {
      key: 'routering.sub-categories.update',
      route: 'v1/configuration/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories/:id'
    },
    {
      key: 'routering.sub-categories.delete',
      route: 'v1/configuration/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories/:id'
    },
    {
      key: 'routering.sub-categories.restore',
      route: 'v1/configuration/head-categories/:head_category_id/main-categories/:main_category_id/sub-categories/:id/restore'
    },

    {key: 'configuration.provinces.index', route: 'v1/configuration/provinces'},
    {key: 'configuration.provinces.create', route: 'v1/configuration/provinces'},
    {key: 'configuration.provinces.read', route: 'v1/configuration/provinces/:id'},
    {key: 'configuration.provinces.update', route: 'v1/configuration/provinces/:id'},
    {key: 'configuration.provinces.delete', route: 'v1/configuration/provinces/:id'},
    {key: 'configuration.provinces.restore', route: 'v1/configuration/provinces/:id/restore'},
    {key: 'configuration.municipalities.index', route: 'v1/configuration/provinces/:province_id/municipalities'},
    {key: 'configuration.municipalities.create', route: 'v1/configuration/provinces/:province_id/municipalities'},
    {key: 'configuration.municipalities.merge', route: 'v1/configuration/provinces/:province_id/municipalities/merge'},
    {key: 'configuration.municipalities.read', route: 'v1/configuration/provinces/:province_id/municipalities/:id'},
    {key: 'configuration.municipalities.update', route: 'v1/configuration/provinces/:province_id/municipalities/:id'},
    {key: 'configuration.municipalities.delete', route: 'v1/configuration/provinces/:province_id/municipalities/:id'},
    {key: 'configuration.municipalities.restore', route: 'v1/configuration/provinces/:province_id/municipalities/:id/restore'},
    {key: 'configuration.cities.index', route: 'v1/configuration/provinces/:province_id/municipalities/:municipality_id/cities'},
    {key: 'configuration.cities.create', route: 'v1/configuration/provinces/:province_id/municipalities/:municipality_id/cities'},
    {key: 'configuration.cities.read', route: 'v1/configuration/provinces/:province_id/municipalities/:municipality_id/cities/:id'},
    {key: 'configuration.cities.update', route: 'v1/configuration/provinces/:province_id/municipalities/:municipality_id/cities/:id'},
    {key: 'configuration.cities.delete', route: 'v1/configuration/provinces/:province_id/municipalities/:municipality_id/cities/:id'},

    {key: 'configuration.address-book.index', route: 'v1/configuration/address-book'},
    {key: 'configuration.address-book.create', route: 'v1/configuration/address-book'},
    {key: 'configuration.address-book.read', route: 'v1/configuration/address-book/:id'},
    {key: 'configuration.address-book.update', route: 'v1/configuration/address-book/:id'},
    {key: 'configuration.address-book.delete', route: 'v1/configuration/address-book/:id'},
    {key: 'configuration.address-book.restore', route: 'v1/configuration/address-book/:id/restore'},
    {key: 'configuration.email-templates.index', route: 'v1/configuration/email-templates'},
    {key: 'configuration.email-templates.create', route: 'v1/configuration/email-templates'},
    {key: 'configuration.email-templates.read', route: 'v1/configuration/email-templates/:id'},
    {key: 'configuration.email-templates.update', route: 'v1/configuration/email-templates/:id'},
    {key: 'configuration.email-templates.delete', route: 'v1/configuration/email-templates/:id'},
    {key: 'configuration.standard-reactions.index', route: 'v1/configuration/standard-reactions'},
    {key: 'configuration.standard-reactions.create', route: 'v1/configuration/standard-reactions'},
    {key: 'configuration.standard-reactions.read', route: 'v1/configuration/standard-reactions/:id'},
    {key: 'configuration.standard-reactions.update', route: 'v1/configuration/standard-reactions/:id'},
    {key: 'configuration.standard-reactions.delete', route: 'v1/configuration/standard-reactions/:id'},
    {key: 'configuration.standard-reactions.restore', route: 'v1/configuration/standard-reactions/:id/restore'},

    {key: 'configuration.public-notifications.index', route: 'v1/configuration/public-notifications'},
    {key: 'configuration.public-notifications.create', route: 'v1/configuration/public-notifications'},
    {key: 'configuration.public-notifications.read', route: 'v1/configuration/public-notifications/:id'},
    {key: 'configuration.public-notifications.update', route: 'v1/configuration/public-notifications/:id'},
    {key: 'configuration.public-notifications.delete', route: 'v1/configuration/public-notifications/:id'},
    {key: 'configuration.public-notifications.restore', route: 'v1/configuration/public-notifications/:id/restore'},

    {key: 'configuration.public-notification-types.index', route: 'v1/configuration/public-notification-types'},
    {key: 'configuration.public-notification-types.create', route: 'v1/configuration/public-notification-types'},
    {key: 'configuration.public-notification-types.read', route: 'v1/configuration/public-notification-types/:id'},
    {key: 'configuration.public-notification-types.update', route: 'v1/configuration/public-notification-types/:id'},
    {key: 'configuration.public-notification-types.delete', route: 'v1/configuration/public-notification-types/:id'},
    {key: 'configuration.public-notification-types.restore', route: 'v1/configuration/public-notification-types/:id/restore'},

    {key: 'configuration.knowledge-base.index', route: 'v1/configuration/knowledge-base'},
    {key: 'configuration.knowledge-base.create', route: 'v1/configuration/knowledge-base'},
    {key: 'configuration.knowledge-base.create-upload-media', route: 'v1/configuration/knowledge-base/media'},
    {key: 'configuration.knowledge-base.read', route: 'v1/configuration/knowledge-base/:id'},
    {key: 'configuration.knowledge-base.update', route: 'v1/configuration/knowledge-base/:id'},
    {key: 'configuration.knowledge-base.update-upload-media', route: 'v1/configuration/knowledge-base/:id/media'},
    {key: 'configuration.knowledge-base.delete', route: 'v1/configuration/knowledge-base/:id'},
    {key: 'configuration.knowledge-base.restore', route: 'v1/configuration/knowledge-base/:id/restore'},
    {key: 'configuration.knowledge-base-articles.index', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles'},
    {key: 'configuration.knowledge-base-articles.create', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles'},
    {
      key: 'configuration.knowledge-base-articles.create-upload-media',
      route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/media'
    },
    {key: 'configuration.knowledge-base-articles.read', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id'},
    {key: 'configuration.knowledge-base-articles.update', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id'},
    {
      key: 'configuration.knowledge-base-articles.update-upload-media',
      route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id/media'
    },
    {key: 'configuration.knowledge-base-articles.delete', route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id'},
    {
      key: 'configuration.knowledge-base-articles.restore',
      route: 'v1/configuration/knowledge-base/:knowledge_base_id/articles/:id/restore'
    },
    {key: 'configuration.knowledge-base.faq.index', route: 'v1/configuration/knowledge-base/faq'},
    {key: 'configuration.knowledge-base.faq.create', route: 'v1/configuration/knowledge-base/faq'},
    {key: 'configuration.knowledge-base.faq.read', route: 'v1/configuration/knowledge-base/faq/:id'},
    {key: 'configuration.knowledge-base.faq.update', route: 'v1/configuration/knowledge-base/faq/:id'},
    {key: 'configuration.knowledge-base.faq.delete', route: 'v1/configuration/knowledge-base/faq/:id'},
    {key: 'configuration.knowledge-base.faq.restore', route: 'v1/configuration/knowledge-base/faq/:id/restore'},
    {key: 'configuration.knowledge-base.support-contact-details', route: 'v1/configuration/knowledge-base/support-contact-details'},

    {key: 'configuration.knowledge-base.changelogs.index', route: 'v1/configuration/knowledge-base/changelogs'},
    {key: 'configuration.knowledge-base.changelogs.create', route: 'v1/configuration/knowledge-base/changelogs'},
    {key: 'configuration.knowledge-base.changelogs.read', route: 'v1/configuration/knowledge-base/changelogs/:id'},
    {key: 'configuration.knowledge-base.changelogs.update', route: 'v1/configuration/knowledge-base/changelogs/:id'},
    {key: 'configuration.knowledge-base.changelogs.delete', route: 'v1/configuration/knowledge-base/changelogs/:id'},
    {
      key: 'configuration.knowledge-base.changelogs.articles.index',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles'
    },
    {
      key: 'configuration.knowledge-base.changelogs.articles.create',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles'
    },
    {
      key: 'configuration.knowledge-base.changelogs.articles.create-upload-media',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/media'
    },
    {
      key: 'configuration.knowledge-base.changelogs.articles.read',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id'
    },
    {
      key: 'configuration.knowledge-base.changelogs.articles.update',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id'
    },
    {
      key: 'configuration.knowledge-base.changelogs.articles.update-upload-media',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id/media'
    },
    {
      key: 'configuration.knowledge-base.changelogs.articles.delete',
      route: 'v1/configuration/knowledge-base/changelogs/:changelog_id/articles/:id'
    },
    {key: 'configuration.knowledge-base.changelogs.types.index', route: 'v1/configuration/knowledge-base/changelogs/types'},
    {key: 'configuration.knowledge-base.changelogs.types.create', route: 'v1/configuration/knowledge-base/changelogs/types'},
    {key: 'configuration.knowledge-base.changelogs.types.read', route: 'v1/configuration/knowledge-base/changelogs/types/:id'},
    {key: 'configuration.knowledge-base.changelogs.types.update', route: 'v1/configuration/knowledge-base/changelogs/types/:id'},
    {key: 'configuration.knowledge-base.changelogs.types.delete', route: 'v1/configuration/knowledge-base/changelogs/types/:id'},
    {key: 'configuration.knowledge-base-organisations.index', route: 'v1/configuration/knowledge-base/organisations'},
    {key: 'configuration.knowledge-base-organisations.create', route: 'v1/configuration/knowledge-base/organisations'},
    {key: 'configuration.knowledge-base-organisations.create-upload-media', route: 'v1/configuration/knowledge-base/organisations/media'},
    {key: 'configuration.knowledge-base-organisations.read', route: 'v1/configuration/knowledge-base/organisations/:id'},
    {key: 'configuration.knowledge-base-organisations.update', route: 'v1/configuration/knowledge-base/organisations/:id'},
    {
      key: 'configuration.knowledge-base-organisations.update-upload-media',
      route: 'v1/configuration/knowledge-base/organisations/:id/media'
    },
    {key: 'configuration.knowledge-base-organisations.delete', route: 'v1/configuration/knowledge-base/organisations/:id'},
    {key: 'configuration.knowledge-base-organisations.restore', route: 'v1/configuration/knowledge-base/organisations/:id/restore'},

    {key: 'instellingen.invoer.products.index', route: 'v1/configuration/report-products'},
    {key: 'instellingen.invoer.products.create', route: 'v1/configuration/report-products'},
    {key: 'instellingen.invoer.products.read', route: 'v1/configuration/report-products/:id'},
    {key: 'instellingen.invoer.products.update', route: 'v1/configuration/report-products/:id'},
    {key: 'instellingen.invoer.products.delete', route: 'v1/configuration/report-products/:id'},
    {key: 'instellingen.invoer.products.restore', route: 'v1/configuration/report-products/:id/restore'},
    {key: 'instellingen.invoer.products.sort', route: 'v1/configuration/report-products/sort'},
    {key: 'instellingen.invoer.statuses.index', route: 'v1/configuration/report-statuses'},
    {key: 'instellingen.invoer.statuses.create', route: 'v1/configuration/report-statuses'},
    {key: 'instellingen.invoer.statuses.read', route: 'v1/configuration/report-statuses/:id'},
    {key: 'instellingen.invoer.statuses.update', route: 'v1/configuration/report-statuses/:id'},
    {key: 'instellingen.invoer.statuses.delete', route: 'v1/configuration/report-statuses/:id'},
    {key: 'instellingen.invoer.statuses.restore', route: 'v1/configuration/report-statuses/:id/restore'},
    {key: 'instellingen.invoer.statuses.sort', route: 'v1/configuration/report-statuses/sort'},
    {key: 'instellingen.invoer.status-codes.index', route: 'v1/configuration/report-status-codes'},
    {key: 'instellingen.invoer.status-codes.create', route: 'v1/configuration/report-status-codes'},
    {key: 'instellingen.invoer.status-codes.read', route: 'v1/configuration/report-status-codes/:id'},
    {key: 'instellingen.invoer.status-codes.update', route: 'v1/configuration/report-status-codes/:id'},
    {key: 'instellingen.invoer.status-codes.delete', route: 'v1/configuration/report-status-codes/:id'},
    {key: 'instellingen.invoer.status-codes.restore', route: 'v1/configuration/report-status-codes/:id/restore'},
    {key: 'instellingen.invoer.status-codes.sort', route: 'v1/configuration/report-status-codes/sort'},
    {key: 'instellingen.invoer.sources.index', route: 'v1/configuration/report-sources'},
    {key: 'instellingen.invoer.sources.create', route: 'v1/configuration/report-sources'},
    {key: 'instellingen.invoer.sources.read', route: 'v1/configuration/report-sources/:id'},
    {key: 'instellingen.invoer.sources.update', route: 'v1/configuration/report-sources/:id'},
    {key: 'instellingen.invoer.sources.delete', route: 'v1/configuration/report-sources/:id'},
    {key: 'instellingen.invoer.sources.restore', route: 'v1/configuration/report-sources/:id/restore'},
    {key: 'instellingen.invoer.sources.sort', route: 'v1/configuration/report-sources/sort'},
    {key: 'instellingen.invoer.histories.index', route: 'v1/configuration/report-history'},
    {key: 'instellingen.invoer.histories.read', route: 'v1/configuration/report-history/:id'},
    {key: 'instellingen.invoer.histories.update', route: 'v1/configuration/report-history/:id'},
    {key: 'instellingen.invoer.causer-types.index', route: 'v1/configuration/report-causer-types'},
    {key: 'instellingen.invoer.causer-types.create', route: 'v1/configuration/report-causer-types'},
    {key: 'instellingen.invoer.causer-types.read', route: 'v1/configuration/report-causer-types/:id'},
    {key: 'instellingen.invoer.causer-types.update', route: 'v1/configuration/report-causer-types/:id'},
    {key: 'instellingen.invoer.causer-types.delete', route: 'v1/configuration/report-causer-types/:id'},
    {key: 'instellingen.invoer.causer-types.restore', route: 'v1/configuration/report-causer-types/:id/restore'},
    {key: 'instellingen.invoer.causer-types.sort', route: 'v1/configuration/report-causer-types/sort'},
    {key: 'instellingen.invoer.reporter-types.index', route: 'v1/configuration/reporter-types'},
    {key: 'instellingen.invoer.reporter-types.create', route: 'v1/configuration/reporter-types'},
    {key: 'instellingen.invoer.reporter-types.read', route: 'v1/configuration/reporter-types/:id'},
    {key: 'instellingen.invoer.reporter-types.update', route: 'v1/configuration/reporter-types/:id'},
    {key: 'instellingen.invoer.reporter-types.delete', route: 'v1/configuration/reporter-types/:id'},
    {key: 'instellingen.invoer.reporter-types.restore', route: 'v1/configuration/reporter-types/:id/restore'},
    {key: 'instellingen.invoer.reporter-types.sort', route: 'v1/configuration/reporter-types/sort'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.index', route: 'v1/configuration/reporter-contact-timeframes'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.create', route: 'v1/configuration/reporter-contact-timeframes'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.read', route: 'v1/configuration/reporter-contact-timeframes/:id'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.update', route: 'v1/configuration/reporter-contact-timeframes/:id'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.delete', route: 'v1/configuration/reporter-contact-timeframes/:id'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.restore', route: 'v1/configuration/reporter-contact-timeframes/:id/restore'},
    {key: 'instellingen.invoer.reporter-contact-timeframes.sort', route: 'v1/configuration/reporter-contact-timeframes/sort'},
    {key: 'instellingen.invoer.company-options.index', route: 'v1/configuration/company-options'},
    {key: 'instellingen.invoer.company-options.create', route: 'v1/configuration/company-options'},
    {key: 'instellingen.invoer.company-options.read', route: 'v1/configuration/company-options/:id'},
    {key: 'instellingen.invoer.company-options.update', route: 'v1/configuration/company-options/:id'},
    {key: 'instellingen.invoer.company-options.delete', route: 'v1/configuration/company-options/:id'},
    {key: 'instellingen.invoer.company-options.restore', route: 'v1/configuration/company-options/:id/restore'},
    {key: 'instellingen.invoer.company-options.sort', route: 'v1/configuration/company-options/sort'},
    {key: 'instellingen.invoer.unusual-incident-causes.index', route: 'v1/configuration/unusual-incident-causes'},
    {key: 'instellingen.invoer.unusual-incident-causes.create', route: 'v1/configuration/unusual-incident-causes'},
    {key: 'instellingen.invoer.unusual-incident-causes.read', route: 'v1/configuration/unusual-incident-causes/:id'},
    {key: 'instellingen.invoer.unusual-incident-causes.update', route: 'v1/configuration/unusual-incident-causes/:id'},
    {key: 'instellingen.invoer.unusual-incident-causes.delete', route: 'v1/configuration/unusual-incident-causes/:id'},
    {key: 'instellingen.invoer.unusual-incident-causes.restore', route: 'v1/configuration/unusual-incident-causes/:id/restore'},
    {key: 'instellingen.invoer.unusual-incident-causes.sort', route: 'v1/configuration/unusual-incident-causes/sort'},
    {key: 'instellingen.invoer.unusual-incident-consequences.index', route: 'v1/configuration/unusual-incident-consequences'},
    {key: 'instellingen.invoer.unusual-incident-consequences.create', route: 'v1/configuration/unusual-incident-consequences'},
    {key: 'instellingen.invoer.unusual-incident-consequences.read', route: 'v1/configuration/unusual-incident-consequences/:id'},
    {key: 'instellingen.invoer.unusual-incident-consequences.update', route: 'v1/configuration/unusual-incident-consequences/:id'},
    {key: 'instellingen.invoer.unusual-incident-consequences.delete', route: 'v1/configuration/unusual-incident-consequences/:id'},
    {key: 'instellingen.invoer.unusual-incident-consequences.restore', route: 'v1/configuration/unusual-incident-consequences/:id/restore'},
    {key: 'instellingen.invoer.unusual-incident-consequences.sort', route: 'v1/configuration/unusual-incident-consequences/sort'},
    {key: 'configuration.client-portal-user-agreement.index', route: 'v1/configuration/client-portal-user-agreement'},
    {key: 'configuration.client-portal-user-agreement.create', route: 'v1/configuration/client-portal-user-agreement'},
    {key: 'configuration.client-portal-user-agreement.read', route: 'v1/configuration/client-portal-user-agreement/:id'},

    {key: 'info.companies.index', route: 'v1/configuration/companies'},
    {key: 'info.companies.create', route: 'v1/configuration/companies'},
    {key: 'info.companies.read', route: 'v1/configuration/companies/:id'},
    {key: 'info.companies.update', route: 'v1/configuration/companies/:id'},
    {key: 'info.companies.update-name', route: 'v1/configuration/companies/:id/update-name'},
    {key: 'info.companies.delete', route: 'v1/configuration/companies/:id'},
    {key: 'info.companies.restore', route: 'v1/configuration/companies/:id/restore'},
    {key: 'info.companies.sort', route: 'v1/configuration/companies/sort'},
    {key: 'info.companies.merge', route: 'v1/configuration/companies/merge'},
    {key: 'info.companies.import', route: 'v1/configuration/companies/import'},
    {key: 'info.companies.export', route: 'v1/configuration/companies/export'},
    {key: 'info.companies.seed', route: 'v1/configuration/companies/seed'},
    {key: 'info.companies.history', route: 'v1/configuration/companies/:id/history'},

    {key: 'info.companies.fix-company-options.index', route: 'v1/configuration/companies/fix-company-options'},
    {key: 'info.companies.fix-company-options.update', route: 'v1/configuration/companies/fix-company-options/:id/:option_id'},

    {key: 'info.reporters.index', route: 'v1/configuration/reporters'},
    {key: 'info.reporters.create', route: 'v1/configuration/reporters'},
    {key: 'info.reporters.read', route: 'v1/configuration/reporters/:id'},
    {key: 'info.reporters.update', route: 'v1/configuration/reporters/:id'},
    {key: 'info.reporters.delete', route: 'v1/configuration/reporters/:id'},
    {key: 'info.reporters.restore', route: 'v1/configuration/reporters/:id/restore'},
    {key: 'info.reporters.disable', route: 'v1/configuration/reporters/:id/disable'},
    {key: 'info.reporters.restore-disable', route: 'v1/configuration/reporters/:id/restore-disable'},
    {key: 'info.reporters.sort', route: 'v1/configuration/reporters/sort'},
    {key: 'info.reporters.merge', route: 'v1/configuration/reporters/merge'},
    {key: 'info.reporters.mass-delete', route: 'v1/configuration/reporters/delete'},
    {key: 'info.reporters.import', route: 'v1/configuration/reporters/import'},
    {key: 'info.reporters.export', route: 'v1/configuration/reporters/export'},
    {key: 'info.reporters.seed', route: 'v1/configuration/reporters/seed'},

    {key: 'overview', route: 'v1/overview'},

    {key: 'reports.open', route: 'v1/reports'},
    {key: 'reports.closed', route: 'v1/reports/closed'},
    {key: 'reports.all', route: 'v1/reports/all'},
    {key: 'reports.search', route: 'v1/reports/search'},
    {key: 'reports.search-download', route: 'v1/reports/search/export'},
    {key: 'reports.count', route: 'v1/reports/count'},
    {key: 'reports.create', route: 'v1/reports'},
    {key: 'reports.read', route: 'v1/reports/:id'},
    {key: 'reports.update', route: 'v1/reports/:id'},
    {key: 'reports.delete', route: 'v1/reports/:id'},
    {key: 'reports.restore', route: 'v1/reports/:id/restore'},
    {key: 'reports.copy', route: 'v1/reports/:id/copy'},
    {key: 'reports.download-attachment', route: 'v1/reports/:id/attachments/:attachment_id'},
    {key: 'reports.download-handling-attachment', route: 'v1/reports/:id/handling/attachments/:attachment_id'},
    {key: 'reports.export', route: 'v1/reports/export/:fileType'},
    {key: 'reports.seed', route: 'v1/reports/seed'},

    {key: 'reports.assign-to-organisation', route: 'v1/reports/:id/assign-to-organisation'},
    {key: 'reports.reassign-to-organisation', route: 'v1/reports/:id/reassign-to-organisation'},
    {key: 'reports.assign-to-department', route: 'v1/reports/:id/assign-to-department'},
    {key: 'reports.assign-to-myself', route: 'v1/reports/:id/assign-to-myself'},
    {key: 'reports.forward', route: 'v1/reports/:id/forward'},
    {key: 'reports.reject', route: 'v1/reports/:id/reject'},
    {key: 'reports.resolve', route: 'v1/reports/:id/resolve'},
    {key: 'reports.suggest-organisation', route: 'v1/reports/:id/suggest-organisation'},
    {key: 'reports.history', route: 'v1/reports/:id/history'},
    {key: 'reports.reopen', route: 'v1/reports/:id/reopen'},
    {key: 'reports.resend-open-confirmation', route: 'v1/reports/:id/resend-open-confirmation'},

    {key: 'reports.multiple.reject', route: 'v1/reports/multiple/reject'},
    {key: 'reports.multiple.resolve', route: 'v1/reports/multiple/resolve'},

    {key: 'knowledge-base.contact-details.index', route: 'v1/knowledge-base/support-contact-details'},
    {key: 'knowledge-base.faq.index', route: 'v1/knowledge-base/faq'},
    {key: 'knowledge-base.support-request', route: 'v1/knowledge-base/support-request'},
    {key: 'knowledge-base.kb.index', route: 'v1/knowledge-base'},
    {key: 'knowledge-base.kb.read', route: 'v1/knowledge-base/:url'},
    {key: 'knowledge-base.kb.articles', route: 'v1/knowledge-base/:articles/articles'},
    {key: 'knowledge-base.kb.article', route: 'v1/knowledge-base/:articles/articles/:article'},

    {key: 'knowledge-base.changelogs.index', route: 'v1/knowledge-base/changelogs'},
    {key: 'knowledge-base.changelogs.read', route: 'v1/knowledge-base/changelogs/:url'},
    {key: 'knowledge-base.changelogs.articles', route: 'v1/knowledge-base/changelogs/:changelog_url/articles'},

    {key: 'knowledge-base.organisations.index', route: 'v1/knowledge-base/organisations'},

    {key: 'statistics.mkc-reports', route: 'v1/statistics/mkc-reports'},
    {key: 'statistics.mkc-reports-download', route: 'v1/statistics/mkc-reports/export'},
    {key: 'statistics.categories-per-municipality', route: 'v1/statistics/categories-per-municipality'},
    {key: 'statistics.categories-per-municipality-download', route: 'v1/statistics/categories-per-municipality/export'},
    {key: 'statistics.categories-per-period', route: 'v1/statistics/categories-per-period'},
    {key: 'statistics.categories-per-period-download', route: 'v1/statistics/categories-per-period/export'},
    {key: 'statistics.sources', route: 'v1/statistics/sources'},
    {key: 'statistics.sources-download', route: 'v1/statistics/sources/export'},
    {key: 'statistics.companies', route: 'v1/statistics/companies'},
    {key: 'statistics.companies-download', route: 'v1/statistics/companies/export'},
    {key: 'statistics.complaints-per-company', route: 'v1/statistics/complaints-per-company'},
    {key: 'statistics.complaints-per-company-download', route: 'v1/statistics/complaints-per-company/export'},
    {key: 'statistics.complaints-per-company-anonymous', route: 'v1/statistics/complaints-per-company/anonymous'},
    {key: 'statistics.complaints-per-company-anonymous-download', route: 'v1/statistics/complaints-per-company/anonymous/export'},
    {key: 'statistics.complaints-per-companies-per-municipality', route: 'v1/statistics/complaints-per-companies-per-municipality'},
    {key: 'statistics.complaints-per-companies-per-municipality-download', route: 'v1/statistics/complaints-per-companies-per-municipality/export'},
    {key: 'statistics.complaints-per-companies-per-province', route: 'v1/statistics/complaints-per-companies-per-province'},
    {key: 'statistics.complaints-per-companies-per-province-download', route: 'v1/statistics/complaints-per-companies-per-province/export'},
    {key: 'statistics.picket-weekly-overview', route: 'v1/statistics/picket-weekly-overview'},
    {key: 'statistics.picket-weekly-overview-download', route: 'v1/statistics/picket-weekly-overview/export'},
    {key: 'statistics.solid-digital-licence-report', route: 'v1/statistics/solid-digital-licence-report'},
    {key: 'statistics.solid-digital-licence-report.download', route: 'v1/statistics/solid-digital-licence-report/export'},

    {key: 'client-portal-groups.index', route: 'v1/client-portal-groups'},
    {key: 'client-portal-groups.create', route: 'v1/client-portal-groups'},
    {key: 'client-portal-groups.read', route: 'v1/client-portal-groups/:id'},
    {key: 'client-portal-groups.sort', route: 'v1/client-portal-groups/sort'},
    {key: 'client-portal-groups.update', route: 'v1/client-portal-groups/:id'},
    {key: 'client-portal-groups.delete', route: 'v1/client-portal-groups/:id'},
    {key: 'client-portal-groups.resend-main-contact-invitation', route: 'v1/client-portal-groups/:id/resend-invitation'},
    {key: 'client-portal-groups.remove-main-contact-2fa-code', route: 'v1/client-portal-groups/:id/remove-2fa-code'},
    {key: 'client-portal-groups.restore', route: 'v1/client-portal-groups/:id/restore'},

    {key: 'archive.anonymized', route: 'v1/archive/anonymized'},
    {key: 'archive.anonymized.export', route: 'v1/archive/anonymized/export'},
    {key: 'archive.anonymized.read', route: 'v1/archive/anonymized/:id'},
    {key: 'archive.archivering.index', route: 'v1/archive/archivering'},
    {key: 'archive.archivering.create', route: 'v1/archive/archivering'},
    {key: 'archive.archivering.read', route: 'v1/archive/archivering/:id'},
    {key: 'archive.archivering.update', route: 'v1/archive/archivering/:id'},
    {key: 'archive.archivering.delete', route: 'v1/archive/archivering/:id'},
    {key: 'archive.archivering.users', route: 'v1/archive/archivering/users'},
    {key: 'archive.archivering.users.all', route: 'v1/archive/archivering/users?all=true'},
    {key: 'archive.archivering.invite', route: 'v1/archive/archivering/:id/invite'},
    {key: 'archive.archivering.resend-invitation', route: 'v1/archive/archivering/:id/resend-invitation'},
    {key: 'archive.archivering.approve', route: 'v1/archive/archivering/:id/approve'},
    {key: 'archive.archivering.reject', route: 'v1/archive/archivering/:id/reject'},
    {key: 'archive.archivering.destroy', route: 'v1/archive/archivering/:id/destroy'},
    {key: 'archive.archivering.export', route: 'v1/archive/archivering/:id/export'},
    {key: 'archive.archivering.certificate', route: 'v1/archive/archivering/:id/certificate'},
    {key: 'archive.archivering.reports', route: 'v1/archive/archivering/:id/reports'},
    {key: 'archive.archivering.reports.delete', route: 'v1/archive/archivering/:archive_collection_id/reports/:id'},
    {key: 'archive.archivering.logs', route: 'v1/archive/archivering/:id/logs'},

    {key: 'sent-emails.index', route: 'v1/sent-emails'},
    {key: 'sent-emails.message', route: 'v1/sent-emails/:id/message'},
    {key: 'sent-emails.logs', route: 'v1/sent-emails/:id/logs'},

    {key: 'sent-emails.events.index', route: 'v1/sent-emails/events'},
    {key: 'sent-emails.events.create', route: 'v1/sent-emails/events'},
    {key: 'sent-emails.events.read', route: 'v1/sent-emails/events/:id'},
    {key: 'sent-emails.events.update', route: 'v1/sent-emails/events/:id'},
    {key: 'sent-emails.events.delete', route: 'v1/sent-emails/events/:id'},
  ];

  constructor() {
  }

  get(name: string, params?): string | null {
    const index: number = _.findIndex(this.endpoints, d => d.key === name);

    if (index !== -1) {
      if (params) {
        const url: string = this.endpoints[index].route;

        return url.replace(/(:[a-zA-Z_]+)/g, m => {
          return params[m];
        });
      } else {
        return this.endpoints[index].route;
      }
    }

    throw new Error('Unknown route URL requested: ' + name);
  }
}

import { EMPTY, Observable, ReplaySubject, Subscriber } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UnsavedChangesDialogComponent } from '../../../@fury/shared/dialog/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { map } from 'rxjs/operators';

export interface ComponentCanDeactivate {
  isLoading: ReplaySubject<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  private dialogRef: MatDialogRef<UnsavedChangesDialogComponent>;

  constructor(private dialog: MatDialog,
  ) {
  }

  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      return component.isLoading.subscribe((value: boolean): void => {
        console.log(value);
        value
          ? this.unsavedChangesDialog().subscribe((response: boolean) => o.next(response))
          : o.next(this.closeDialogIfOpen());
      });
    });
  }

  closeDialogIfOpen(): boolean {
    this.dialog.closeAll();
    this.dialogRef = undefined;

    return true;
  }

  unsavedChangesDialog(): Observable<boolean> {
    if (typeof this.dialogRef === 'undefined') {
      this.dialogRef = this.dialog.open(UnsavedChangesDialogComponent);

      return this.dialogRef.afterClosed().pipe(map((result: boolean): boolean => {
        this.dialogRef = undefined;
        return result;
      }));
    } else {
      return EMPTY;
    }
  }
}

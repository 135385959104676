<!--suppress AngularUndefinedTag -->
<ng-container *ngIf="reportPrintService.report">
  <table class="print-container">
    <thead class="print-header">
    <tr>
      <th>
        <h1>
          Overzicht melding {{ reportPrintService.report.number }}
          -
          {{ reportPrintService.report.received_at | date:'dd-MM-yy' }}
        </h1>
      </th>
    </tr>
    </thead>
    <tbody class="print-content">
    <tr>
      <td>
        <div class="content">
          <h2>Algemene gegevens</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Registratienummer:</th>
                    <td>{{ reportPrintService.report.number }}</td>
                  </tr>
                  <tr>
                    <th>Datum en tijd opgeslagen:</th>
                    <td>{{ reportPrintService.report.received_at | date:'dd-MM-yy HH:mm' }}</td>
                  </tr>
                  <ng-container *ngIf="!reportPrintService.report?.unusualIncident">
                    <tr>
                      <th>Datum & tijd overlast:</th>
                      <td>{{ reportPrintService.report.complaint?.noticed_at | date:'dd-MM-yy HH:mm' }}</td>
                    </tr>
                    <tr *ngIf="reportPrintService.report.report_type_id === 2">
                      <th>Tot einde:</th>
                      <td>{{ reportPrintService.report.complaint.end_at | date:'dd-MM-yy HH:mm' }}</td>
                    </tr>
                  </ng-container>
                  <ng-container *ngIf="reportPrintService.report?.unusualIncident">
                    <tr>
                      <th>Datum & tijdstip aanvang:</th>
                      <td>{{ reportPrintService.report.complaint.noticed_at | date:'dd-MM-yy HH:mm' }}</td>
                    </tr>
                    <tr>
                      <th>Datum & tijdstip verwachte einde:</th>
                      <td>{{ reportPrintService.report.complaint.end_at | date:'dd-MM-yy HH:mm' }}</td>
                    </tr>
                  </ng-container>
                  <tr>
                    <th>Resultaat afhandeling:</th>
                    <td>
                      <ng-container *ngIf="reportPrintService.report.status_code">
                        {{ reportPrintService.report.status_code?.name }}
                        /
                        {{ reportPrintService.report.status_code?.description }}
                      </ng-container>
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Omgevingsdienst:</th>
                    <td>
                      <span *ngIf="reportPrintService.report.organisation">
                        {{ reportPrintService.report.organisation.name }}
                        <small>({{ reportPrintService.report.organisation.full_name }})</small>
                      </span>
                      <span *ngIf="!reportPrintService.report.organisation">-</span>
                    </td>
                  </tr>
                  <tr>
                    <th>Status melding:</th>
                    <td [style.background]="reportPrintService.report.status.color">
                      {{ reportPrintService.report.status.display_name }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Omschrijving overlast</h2>
          <table style="width:100%">
            <tr>
              <th>Wijze van melden:</th>
              <td>{{ reportPrintService.report.source?.name }}</td>
            </tr>
            <tr>
              <th>Categorieën melding:</th>
              <td>
                <ng-container *ngIf="reportPrintService.report.categories.length">
                  <div *ngFor="let category of reportPrintService.report.categories">
                    {{ category.head_category?.name }}
                    <ng-container *ngIf="category.main_category">
                      >
                      {{ category.main_category?.name }}
                      <ng-container *ngIf="category.sub_category">
                        >
                        {{ category.sub_category?.name }}
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>
            <ng-container *ngIf="reportPrintService.report?.unusualIncident">
              <tr>
                <th>Oorzaak:</th>
                <td>{{ reportPrintService.report.unusualIncident.cause?.name }}</td>
              </tr>
              <tr>
                <th>Verwachte gevolgen:</th>
                <td>{{ reportPrintService.report.unusualIncident.consequence?.name }}</td>
              </tr>
              <tr>
                <th>Toelichting:</th>
                <td>{{ reportPrintService.report.description }}</td>
              </tr>
              <tr>
                <th>Zorgt het voor eventuele overlast voor de omgeving?</th>
                <td>{{ reportPrintService.report.unusualIncident.environment_nuisance }}</td>
              </tr>
              <tr>
                <th>Als er stoffen zijn vrijgekomen, welke zijn dat?</th>
                <td>{{ reportPrintService.report.unusualIncident.released_substances }}</td>
              </tr>
              <tr>
                <th>Wat is de precieze locatie waar het voorval heeft plaats gevonden?</th>
                <td>{{ reportPrintService.report.unusualIncident.location }}</td>
              </tr>
              <tr>
                <th>Is de oorzaak al bekend?</th>
                <td>{{ reportPrintService.report.unusualIncident.cause_known ? 'Ja' : 'Nee' }}</td>
              </tr>
              <tr>
                <th>Is het voorval nog gaande?</th>
                <td>{{ reportPrintService.report.unusualIncident.ongoing_incident ? 'Ja' : 'Nee' }}</td>
              </tr>
              <tr>
                <th>Welke maatregelen geeft u getroffen mbr voorval?</th>
                <td>{{ reportPrintService.report.unusualIncident.measures_already_taken }}</td>
              </tr>
              <tr>
                <th>Welke maatregelen gaan er getroffen worden?</th>
                <td>{{ reportPrintService.report.unusualIncident.measures_will_be_taken }}</td>
              </tr>
              <tr>
                <th>Heeft u andere instanties op de hoogte gebracht?</th>
                <td>{{ reportPrintService.report.unusualIncident.informed_authorities }}</td>
              </tr>
            </ng-container>
            <tr *ngIf="!reportPrintService.report?.unusualIncident">
              <th>Toelichting:</th>
              <td>{{ reportPrintService.report.description }}</td>
            </tr>
            <tr>
              <th>Interne notitie:</th>
              <td>{{ reportPrintService.report.internal_notes }}</td>
            </tr>
            <tr>
              <th>Bijlagen:</th>
              <td>
                <ng-container *ngIf="!reportPrintService.report.attachments?.total">
                  Geen bijlagen
                </ng-container>
                <ng-container *ngIf="reportPrintService.report.attachments?.total">
                  <div *ngIf="reportPrintService.report.attachments.media.length" class="media">
                    <div *ngFor="let photo of reportPrintService.report.attachments.media; let i = index"
                         class="media-div">
                      <img *ngIf="photo.type === 'image'" [src]="photo.thumb" alt="">
                      <video *ngIf="photo.type === 'video'" [src]="photo.src"></video>
                    </div>
                  </div>
                  <div *ngIf="reportPrintService.report.attachments.documents.length">
                    <ng-container *ngFor="let document of reportPrintService.report.attachments.documents">
                      <ng-container *ngIf="document.type === 'document'">
                        {{ document.original_filename }}<br>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Locatie overlast</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%;">
                <table style="width:100%">
                  <tr>
                    <th>Onbekende locatie:</th>
                    <td>{{ reportPrintService.report.complaint.location_unknown === false ? 'Nee' : 'Ja' }}</td>
                  </tr>
                  <tr>
                    <th>Locatie van melding is gelijk aan woonadres:</th>
                    <td>{{ reportPrintService.report.complaint.same_as_reporter_address === false ? 'Nee' : 'Ja' }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table *ngIf="!reportPrintService.report.complaint.location_unknown" style="width:100%">
                  <ng-container *ngIf="reportPrintService.report.surrounding_id === 2">
                    <tr>
                      <th>Latitude:</th>
                      <td>{{ reportPrintService.report.complaint.lat }}</td>
                    </tr>
                    <tr>
                      <th>Longitude:</th>
                      <td>{{ reportPrintService.report.complaint.lng }}</td>
                    </tr>
                  </ng-container>
                  <tr>
                    <th>Postcode:</th>
                    <td>{{ reportPrintService.report.complaint.postalcode }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer:</th>
                    <td>{{ reportPrintService.report.complaint.house_number }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer toevoeging:</th>
                    <td>{{ reportPrintService.report.complaint.house_number_addition }}</td>
                  </tr>
                  <tr>
                    <th>Straatnaam:</th>
                    <td>{{ reportPrintService.report.complaint.streetname }}</td>
                  </tr>
                  <tr>
                    <th>Plaats:</th>
                    <td>{{ reportPrintService.report.complaint.city }}</td>
                  </tr>
                  <tr>
                    <th>Gemeente:</th>
                    <td>{{ reportPrintService.report.complaint.municipality }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table style="width:100%">
            <tr *ngIf="reportPrintService.report.complaint.location_description">
              <th>Omschrijf locatie:</th>
              <td class="full-width">{{ reportPrintService.report.complaint.location_description }}</td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Weer</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Temperatuur:</th>
                    <td>
                      <i class="wi wi-thermometer"></i>
                      <ng-container
                          *ngIf="reportPrintService.report.weather?.temperature">{{ reportPrintService.report.weather.temperature }}
                        °C
                      </ng-container>
                      <ng-container *ngIf="!reportPrintService.report.weather?.temperature">-</ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th>Windrichting:</th>
                    <td>
                      <i class="wi wi-wind-direction"></i>
                      <ng-container *ngIf="reportPrintService.report.weather?.wind_direction">
                        {{ reportPrintService.report.weather?.wind_direction }}
                        <ng-container *ngIf="reportPrintService.report.weather?.wind_direction_degrees">
                          / {{ reportPrintService.report.weather?.wind_direction_degrees}}°
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="!reportPrintService.report.weather?.wind_direction">-</ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th>Windsnelheid:</th>
                    <td>
                      <i class="wi wi-strong-wind"></i>
                      <ng-container
                          *ngIf="reportPrintService.report.weather?.wind_speed">{{ reportPrintService.report.weather?.wind_speed }}
                        m/s
                      </ng-container>
                      <ng-container *ngIf="!reportPrintService.report.weather?.wind_speed">-</ng-container>
                    </td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Luchtdruk:</th>
                    <td>
                      <i class="wi wi-barometer"></i>
                      <ng-container
                          *ngIf="reportPrintService.report.weather?.air_pressure">{{ reportPrintService.report.weather?.air_pressure }}
                        hPa
                      </ng-container>
                      <ng-container *ngIf="!reportPrintService.report.weather?.air_pressure">-</ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th>Vochtigheid:</th>
                    <td>
                      <i class="wi wi-humidity"></i>
                      <ng-container
                          *ngIf="reportPrintService.report.weather?.humidity">{{ reportPrintService.report.weather?.humidity }}
                        %
                      </ng-container>
                      <ng-container *ngIf="!reportPrintService.report.weather?.humidity">-</ng-container>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <div class="weather-info">
                  Weergegevens zijn op basis van dichtbijzijnde meetstation, waarden kunnen afwijken bij de daadwerkelijk locatie van overlast.
                </div>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Contactgegevens melder</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table *ngIf="reportPrintService.report.reporter?.anonymous" style="width:100%">
                  <tr>
                    <th>Anoniem:</th>
                    <td>{{ reportPrintService.report.reporter.anonymous === true ? 'Ja' : 'Nee'}}</td>
                  </tr>
                </table>
                <table *ngIf="!reportPrintService.report.reporter?.anonymous" style="width:100%">
                  <tr>
                    <th>Anoniem:</th>
                    <td>{{ reportPrintService.report.reporter.anonymous === true ? 'Ja' : 'Nee'}}</td>
                  </tr>
                  <tr>
                    <th>Melder type:</th>
                    <td>{{ reportPrintService.report.reporter.type }}</td>
                  </tr>
                  <tr *ngIf="reportPrintService.report.reporter.type_name === 'company'">
                    <th>Organisatie:</th>
                    <td>{{ reportPrintService.report.reporter.organisation }}</td>
                  </tr>
                  <tr>
                    <th>Aanhef:</th>
                    <td>{{ reportPrintService.report.reporter.prefix }}</td>
                  </tr>
                  <tr>
                    <th>Voorletters:</th>
                    <td>{{ reportPrintService.report.reporter.initials }}</td>
                  </tr>
                  <tr>
                    <th>Tussenvoegsel:</th>
                    <td>{{ reportPrintService.report.reporter.middlename }}</td>
                  </tr>
                  <tr>
                    <th>Achternaam:</th>
                    <td>{{ reportPrintService.report.reporter.lastname }}</td>
                  </tr>
                  <tr>
                    <th>Telefoonnummer:</th>
                    <td>{{ reportPrintService.report.reporter.phone }}</td>
                  </tr>
                  <tr>
                    <th>E-mail:</th>
                    <td>{{ reportPrintService.report.reporter.email }}</td>
                  </tr>
                  <tr>
                    <th>Verstuur e-mail notificaties:</th>
                    <td>{{ reportPrintService.report.reporter.notification === true ? 'Ja' : 'Nee' }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table *ngIf="!reportPrintService.report.reporter?.anonymous" style="width:100%">
                  <tr>
                    <th>Postcode:</th>
                    <td>{{ reportPrintService.report.reporter.postalcode }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer:</th>
                    <td>{{ reportPrintService.report.reporter.house_number }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer toevoeging:</th>
                    <td>{{ reportPrintService.report.reporter.house_number_addition }}</td>
                  </tr>
                  <tr>
                    <th>Straatnaam:</th>
                    <td>{{ reportPrintService.report.reporter.streetname }}</td>
                  </tr>
                  <tr>
                    <th>Plaats:</th>
                    <td>{{ reportPrintService.report.reporter.city }}</td>
                  </tr>
                  <tr>
                    <th>Gemeente:</th>
                    <td>{{ reportPrintService.report.reporter.municipality }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Details vermoedelijke veroorzaker</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table *ngIf="reportPrintService.report.suspect" style="width:100%">
                  <tr>
                    <th>Type vermoedelijke veroorzaker:</th>
                    <td>{{ reportPrintService.report.suspect.type }}</td>
                  </tr>
                  <tr *ngIf="reportPrintService.report.suspect.type_veroorzaker">
                    <th>Onderwerp vermoedelijke veroorzaker:</th>
                    <td>{{ reportPrintService.report.suspect.type_veroorzaker.name }}</td>
                  </tr>
                  <tr>
                    <th>Naam/organisatie vermoedelijke:</th>
                    <td>{{ reportPrintService.report.suspect.name }}</td>
                  </tr>
                  <tr *ngFor="let option of reportPrintService.report.suspect.options">
                    <th>{{ option.display_name }}:</th>
                    <td>{{ option.checked ? 'Ja' : 'Nee' }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table *ngIf="reportPrintService.report.suspect" style="width:100%">
                  <tr>
                    <th>Postcode:</th>
                    <td>{{ reportPrintService.report.suspect.postalcode }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer:</th>
                    <td>{{ reportPrintService.report.suspect.house_number }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer toevoeging:</th>
                    <td>{{ reportPrintService.report.suspect.house_number_addition }}</td>
                  </tr>
                  <tr>
                    <th>Straatnaam:</th>
                    <td>{{ reportPrintService.report.suspect.streetname }}</td>
                  </tr>
                  <tr>
                    <th>Plaats:</th>
                    <td>{{ reportPrintService.report.suspect.city }}</td>
                  </tr>
                  <tr>
                    <th>Gemeente:</th>
                    <td>{{ reportPrintService.report.suspect.municipality }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table *ngIf="reportPrintService.report.suspect" style="width:100%">
            <tr>
              <th>Informatie regime:</th>
              <td [innerHTML]="reportPrintService.report.suspect.informatie_regime"></td>
            </tr>
            <tr>
              <th>Notities:</th>
              <td [innerHTML]="reportPrintService.report.suspect.notes"></td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Details veroorzaker</h2>
          <table *ngIf="reportPrintService.report.causer" style="width:100%">
            <tr>
              <th style="width:65%;">Gaat de gemelde overlast ook over het daadwerkelijke bedrijf?</th>
              <td style="width:35%;">{{ reportPrintService.report.causer?.suspect_matches_causer }}</td>
            </tr>
          </table>
          <table *ngIf="reportPrintService.report.causer?.suspect_matches_causer === 'Nee'" style="width:100%;">
            <tr>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Type:</th>
                    <td>{{ reportPrintService.report.causer.type }}</td>
                  </tr>
                  <tr *ngIf="reportPrintService.report.causer.type_veroorzaker">
                    <th>Type veroorzaker:</th>
                    <td> {{ reportPrintService.report.causer.type_veroorzaker.name }}</td>
                  </tr>
                  <tr>
                    <th>Naam/organisatie:</th>
                    <td>{{ reportPrintService.report.causer.name }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Postcode:</th>
                    <td>{{ reportPrintService.report.causer.postalcode }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer:</th>
                    <td>{{ reportPrintService.report.causer.house_number }}</td>
                  </tr>
                  <tr>
                    <th>Huisnummer toevoeging:</th>
                    <td>{{ reportPrintService.report.causer.house_number_addition }}</td>
                  </tr>
                  <tr>
                    <th>Straatnaam:</th>
                    <td>{{ reportPrintService.report.causer.streetname }}</td>
                  </tr>
                  <tr>
                    <th>Plaats:</th>
                    <td>{{ reportPrintService.report.causer.city }}</td>
                  </tr>
                  <tr>
                    <th>Gemeente:</th>
                    <td>{{ reportPrintService.report.causer.municipality }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Afhandeling</h2>
          <table style="width:100%">
            <tr>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Behandelaar:</th>
                    <td>{{ reportPrintService.report.handling?.user?.name }}</td>
                  </tr>
                  <tr>
                    <th>Werkelijke datum & tijd afhandeling:</th>
                    <td>{{ reportPrintService.report.handling?.closed_at | date:'dd-MM-YY HH:mm' }}</td>
                  </tr>
                  <tr>
                    <th>Ter plaatse geweest:</th>
                    <td>{{ reportPrintService.report.handling?.location_visit ? 'Ja' : 'Nee' }}</td>
                  </tr>
                  <tr>
                    <th>Zaaknummer VTH-systeem:</th>
                    <td>{{ reportPrintService.report.vth_case_number }}</td>
                  </tr>
                </table>
              </td>
              <td style="width:50%">
                <table style="width:100%">
                  <tr>
                    <th>Status:</th>
                    <td>{{ reportPrintService.report.status.display_name }}</td>
                  </tr>
                  <tr>
                    <th>Resultaat afhandeling:</th>
                    <td>
                      <ng-container *ngIf="reportPrintService.report.status_code">
                        {{ reportPrintService.report.status_code?.name }}
                        /
                        {{ reportPrintService.report.status_code?.description }}
                      </ng-container>
                    </td>
                  </tr>
                  <tr>
                    <th>Bovenstaande bericht niet versturen naar melder:</th>
                    <td>{{ reportPrintService.report.handling?.skip_notes === true ? 'Ja' : 'Nee' }}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table style="width:100%">
            <tr>
              <th>Bericht naar de melder:</th>
              <td [innerHTML]="reportPrintService.report.handling?.notes"></td>
            </tr>
            <tr>
              <th>Toelichting afhandeling (intern):</th>
              <td>
                <div *ngFor="let note of reportPrintService.report.handling?.internal_notes" class="note-container">
                  <h3 class="note-header">
                    {{ note.created_at | date:'dd-MM-yy HH:mm' }}
                    <ng-container *ngIf="note.user">{{ note.user.name }}</ng-container>
                    <ng-container *ngIf="!note.user">Onbekend</ng-container>
                    <small *ngIf="note.is_updated"> (aangepast)</small>
                  </h3>
                  <div [innerHTML]="note.notes" class="note-content"></div>
                </div>
              </td>
            </tr>
          </table>
          <table style="width:100%">
            <tr>
              <th>Bijlagen:</th>
              <td>
                <ng-container *ngIf="!reportPrintService.report.handling?.attachments?.total">
                  Geen bijlagen
                </ng-container>
                <ng-container *ngIf="reportPrintService.report.handling?.attachments?.total">
                  <div *ngIf="reportPrintService.report.handling?.attachments.media.length" class="media">
                    <div *ngFor="let photo of reportPrintService.report.handling?.attachments.media; let i = index"
                         class="media-div">
                      <img *ngIf="photo.type === 'image'" [src]="photo.thumb" alt="">
                      <video *ngIf="photo.type === 'video'" [src]="photo.src"></video>
                    </div>
                    <div *ngIf="reportPrintService.report.handling?.attachments.documents.length">
                      <ng-container *ngFor="let document of reportPrintService.report.handling?.attachments.documents">
                        <ng-container *ngIf="document.type === 'document'">
                          {{ document.original_filename }}<br>
                        </ng-container>
                      </ng-container>
                    </div>
                  </div>
                </ng-container>
              </td>
            </tr>
          </table>
        </div>

        <div class="content">
          <h2>Contact melder</h2>
          <table style="width:100%">
            <tr>
              <th>Contact:</th>
              <td>{{ reportPrintService.report.reporter?.contacted_manually === true ? 'Ja' : 'Nee' }}</td>
            </tr>
            <tr>
              <th>Datum & tijd contact melder:</th>
              <td>{{ reportPrintService.report.reporter?.contact_timeframe?.name }}</td>
            </tr>
            <tr>
              <th>Toelichting contact melder:</th>
              <td>{{ reportPrintService.report.reporter?.contacted_notes }}</td>
            </tr>
          </table>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</ng-container>

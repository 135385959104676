import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { Municipality } from '../../interfaces/configuration/municipality';

@Injectable()

export class MunicipalityService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  all(province_id: number): Observable<Municipality[] | boolean> {
    return new Observable<Municipality[] | boolean>((o: Subscriber<Municipality[] | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.municipalities.index', {':province_id': province_id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Municipality[]>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  get(province_id: number, id: number): Observable<Municipality | boolean> {
    return new Observable<Municipality | boolean>((o: Subscriber<Municipality | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('configuration.municipalities.read', {':province_id': province_id, ':id': id}))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<Municipality>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  create(province_id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('configuration.municipalities.create', {':province_id': province_id}), formData);
  }

  update(province_id: number, id: number, formData: any): Observable<ServerResponse> {
    return this.api.put(this.apiEndpoints.get('configuration.municipalities.update', {':province_id': province_id, ':id': id}), formData);
  }

  delete(province_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.delete(this.apiEndpoints.get('configuration.municipalities.delete', {':province_id': province_id, ':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  restore(province_id: number, id: number): Observable<boolean> {
    return new Observable<boolean>((o: Subscriber<boolean>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('configuration.municipalities.restore', {':province_id': province_id, ':id': id}))
        .subscribe((response: ServerResponse): void => {
            if (response) {
              o.next(true);
            } else {
              o.next(false);
            }
          },
          (): void => o.next(false));

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  merge(province_id: number, formData: any): Observable<ServerResponse> {
    return this.api.post(this.apiEndpoints.get('configuration.municipalities.merge', {':province_id': province_id}), formData);
  }
}

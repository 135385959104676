// noinspection JSUnusedLocalSymbols,JSUnusedGlobalSymbols

import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from './api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from './api-endpoints.service';
import { AddressBookType } from '../../interfaces/configuration/address-book-type';
import { environment } from '../../../environments/environment';
import { Organisation } from '../../interfaces/routering/organisation';
import { Department } from '../../interfaces/routering/department';
import { User } from '../../interfaces/routering/user';
import { Municipality } from '../../interfaces/configuration/municipality';
import { City } from '../../interfaces/configuration/city';
import { Role } from '../../interfaces/routering/role';
import { MailType } from '../../interfaces/configuration/mail-type';
import { ReportHistoryType } from '../../interfaces/configuration/report-history-type';
import { ReportProduct } from '../../interfaces/configuration/report-product';
import { ReportSource } from '../../interfaces/configuration/report-source';
import { ReportStatus } from '../../interfaces/configuration/report-status';
import { ReportStatusCode } from '../../interfaces/configuration/report-status-code';
import { HeadCategoriesTypes } from '../../interfaces/configuration/head-categories-types';
import { HeadCategory } from '../../interfaces/routering/head-category';
import { MainCategoriesSurroundings } from '../../interfaces/configuration/main-categories-surroundings';
import { MainCategory } from '../../interfaces/routering/main-category';
import { SubCategory } from '../../interfaces/routering/sub-category';
import { Company } from '../../interfaces/info/company';
import { AddressBook } from '../../interfaces/configuration/address-book';
import { StandardReaction } from '../../interfaces/configuration/standard-reaction';
import { IntegratedOptions } from '../../interfaces/base/integrated-options';
import { ReportCauserType } from '../../interfaces/configuration/report-causer-type';
import { ReporterType } from '../../interfaces/configuration/reporter-type';
import { CompanyOption } from '../../interfaces/configuration/company-option';
import { UnusualIncidentCause } from '../../interfaces/configuration/unusual-incident-cause';
import { UnusualIncidentConsequence } from '../../interfaces/configuration/unusual-incident-consequence';
import { ChangelogType } from '../../interfaces/configuration/changelog-type';
import { ReporterContactTimeframe } from '../../interfaces/configuration/reporter-contact-timeframe';
import { PublicNotificationType } from '../../interfaces/configuration/public-notification-type';
import { Province } from '../../interfaces/configuration/province';
import { ContentVisibility } from '../../interfaces/configuration/content-visibility';
import { Report } from '../../interfaces/report/report';
import { Permission } from '../../interfaces/routering/permission';
import { MailTag } from '../../interfaces/sent-emails/mail-tag';
import { MailEvent } from '../../interfaces/sent-emails/mail-event';
import { MailLogLevel } from '../../interfaces/sent-emails/mail-log-level';
import { FilterData, Filters } from '../../interfaces/base/filters';

@Injectable()

export class FiltersService {

  public filters: Filters = null;

  private filterData: FilterData = {
    addressBook: [],
    changelogTypes: [],
    companyOptions: [],
    departments: [],
    headCategories: [],
    headCategoriesTypes: [],
    historyEntries: [],
    historyTypes: [],
    mainCategories: [],
    mainCategoriesSurroundings: [],
    organisations: [],
    reportCauserTypes: [],
    reportProducts: [],
    reportSources: [],
    reportStatusCodes: [],
    reportStatuses: [],
    reporterContactTimeframes: [],
    reporterTypes: [],
    standardReactions: [],
    subCategories: [],
    unusualIncidentCauses: [],
    unusualIncidentConsequences: [],
    users: [],
    publicNotificationTypes: [],
    contentVisibility: [],
    archiveUsers: [],
    archiveUsersAll: [],
    mailEvents: [],
    mailLogLevels: [],
    mailSubjects: [],
    mailTags: [],
  };

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  activeStatuses(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Alle',
          id: null
        },
        {
          name: 'Ja',
          id: true
        },
        {
          name: 'Nee',
          id: false
        }
      ]);
    });
  }

  yesNo(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Ja',
          id: true
        },
        {
          name: 'Nee',
          id: false
        }
      ]);
    });
  }

  yesNoUnknown(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Ja',
          id: 'Ja'
        },
        {
          name: 'Nee',
          id: 'Nee'
        },
        {
          name: 'Onbekend',
          id: 'Onbekend'
        }
      ]);
    });
  }

  personPrefix(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Heer',
          id: 'Heer'
        },
        {
          name: 'Mevrouw',
          id: 'Mevrouw'
        },
        {
          name: 'Neutraal',
          id: 'Neutraal',
        }
      ]);
    });
  }

  clientPortalAccessType(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Gemeente',
          id: 'municipality'
        },
        {
          name: 'Provincie',
          id: 'province'
        }
      ]);
    });
  }

  archiveCollectionStatus(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          id: 'created',
          name: 'Aangemaakt'
        },
        {
          id: 'in-review',
          name: 'Beoordelen'
        },
        {
          id: 'rejected',
          name: 'Afgewezen'
        },
        {
          id: 'approved',
          name: 'Geaccordeerd'
        },
        {
          id: 'destroyed',
          name: 'Vernietigd'
        },
      ]);
    });
  }

  /*
    companyRisks(): Observable<IntegratedOptions[]> {
      return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
        o.next([
          {
            name: 'Alle',
            id: null
          },
          {
            name: 'BRZO',
            id: 'brzo'
          },
          {
            name: 'Provinciaal bedrijf',
            id: 'provinciaal_bedrijf'
          }
        ]);
      });
    }
  */

  for(): Observable<IntegratedOptions[]> {
    return new Observable<IntegratedOptions[]>((o: Subscriber<IntegratedOptions[]>): void => {
      o.next([
        {
          name: 'Alle',
          id: null
        },
        {
          name: 'Voltooien',
          id: 'resolved'
        },
        {
          name: 'Afwijzen',
          id: 'rejected'
        },
      ]);
    });
  }

  organisations(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.organisations.length) {
        o.next(this.filterData.organisations);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisations !== 'undefined') {
            this.filters.organisations.isLoading = true;
          }
        }
        subscription = this.getOrganisations().subscribe((data: Organisation[]): void => {
          this.filterData.organisations = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisations !== 'undefined') {
              this.filters.organisations.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        if (!environment.production) {
          console.log('WebSocket: subscribed to event for "filters" channel: ' + '.organisations.changed');
        }
        window.Echo.private('filters').listen('.organisations.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.organisations.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.organisations !== 'undefined') {
              this.filters.organisations.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getOrganisations().subscribe((data: Organisation[]): void => {
            o.next(data);
            this.filterData.organisations = data;
            if (this.filters !== null) {
              if (typeof this.filters.organisations !== 'undefined') {
                this.filters.organisations.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getOrganisations(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisations'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });
      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationsForActiveUser(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      let subscription: Subscription = null;

      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.organisationsForActiveUser !== 'undefined') {
          this.filters.organisationsForActiveUser.isLoading = true;
        }
      }
      subscription = this.api.get(this.apiEndpoints.get('filters.organisations-for-active-user'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (this.filters !== null) {
            if (typeof this.filters.organisationsForActiveUser !== 'undefined') {
              this.filters.organisationsForActiveUser.isLoading = false;
            }
          }

          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          if (this.filters !== null) {
            if (typeof this.filters.organisationsForActiveUser !== 'undefined') {
              this.filters.organisationsForActiveUser.isLoading = false;
            }
          }
          o.next([]);
        });

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  departments(event: 'start' | 'stop' = 'start'): Observable<Department[]> {
    if (event === 'start') {
      return new Observable<Department[]>((o: Subscriber<Department[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.departments.length) {
          o.next(this.filterData.departments);
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.departments !== 'undefined') {
              this.filters.departments.isLoading = true;
            }
          }
          subscription = this.getDepartments().subscribe((data: Department[]): void => {
            o.next(data);
            this.filterData.departments = data;
            if (this.filters !== null) {
              if (typeof this.filters.departments !== 'undefined') {
                this.filters.departments.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.departments.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.departments !== 'undefined') {
                this.filters.departments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getDepartments().subscribe((data: Department[]): void => {
              o.next(data);
              this.filterData.departments = data;
              if (this.filters !== null) {
                if (typeof this.filters.departments !== 'undefined') {
                  this.filters.departments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.departments.changed');
      }
    }
  }

  getDepartments(): Observable<Department[]> {
    return new Observable<Department[]>((o: Subscriber<Department[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.departments'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationsWithDepartments(event: 'start' | 'stop' = 'start'): Observable<Organisation[]> {
    if (event === 'start') {
      return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
            this.filters.organisationsWithDepartments.isLoading = true;
          }
        }
        subscription = this.getOrganisationsWithDepartments().subscribe((data: Organisation[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
              this.filters.organisationsWithDepartments.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          if (!environment.production) {
            console.log('WebSocket: subscribed to event for "filters" channel: ' + '.organisations.changed');
          }
          window.Echo.private('filters').listen('.organisations.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.organisations.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
                this.filters.organisationsWithDepartments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationsWithDepartments().subscribe((data: Organisation[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationsWithDepartments !== 'undefined') {
                  this.filters.organisationsWithDepartments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.organisations.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.organisations.changed');
      }
    }
  }

  getOrganisationsWithDepartments(): Observable<Organisation[]> {
    return new Observable<Organisation[]>((o: Subscriber<Organisation[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisations-with-departments'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationMunicipalities(event: 'start' | 'stop' = 'start'): Observable<Municipality[]> {
    if (event === 'start') {
      return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationMunicipalities !== 'undefined') {
            this.filters.organisationMunicipalities.isLoading = true;
          }
        }
        subscription = this.getOrganisationMunicipalities().subscribe((data: Municipality[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationMunicipalities !== 'undefined') {
              this.filters.organisationMunicipalities.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.municipalities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.municipalities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationMunicipalities !== 'undefined') {
                this.filters.organisationMunicipalities.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationMunicipalities().subscribe((data: Municipality[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationMunicipalities !== 'undefined') {
                  this.filters.organisationMunicipalities.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.municipalities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.municipalities.changed');
      }
    }
  }

  getOrganisationMunicipalities(): Observable<Municipality[]> {
    return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-municipalities'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationDepartments(organisation_id: number, event: 'start' | 'stop' = 'start'): Observable<Department[]> {
    if (event === 'start') {
      return new Observable<Department[]>((o: Subscriber<Department[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationDepartments !== 'undefined') {
            this.filters.organisationDepartments.isLoading = true;
          }
        }

        const subscription: Subscription = this.getOrganisationDepartments(organisation_id).subscribe((data: Department[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationDepartments !== 'undefined') {
              this.filters.organisationDepartments.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.departments.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationDepartments !== 'undefined') {
                this.filters.organisationDepartments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationDepartments(organisation_id).subscribe((data: Department[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationDepartments !== 'undefined') {
                  this.filters.organisationDepartments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        return (): void => {
          subscription.unsubscribe();
        };
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.departments.changed');
      }
    }
  }

  getOrganisationDepartments(organisation_id: number): Observable<Department[]> {
    return new Observable<Department[]>((o: Subscriber<Department[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisation-departments', {':id': organisation_id}), null, true).subscribe(
        (response: ServerResponse) => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        () => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationUsers(organisation_id: number, event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationUsers !== 'undefined') {
            this.filters.organisationUsers.isLoading = true;
          }
        }

        const subscription: Subscription = this.getOrganisationUsers(organisation_id).subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationUsers !== 'undefined') {
              this.filters.organisationUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationUsers !== 'undefined') {
                this.filters.organisationUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationUsers(organisation_id).subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationUsers !== 'undefined') {
                  this.filters.organisationUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        return (): void => {
          subscription.unsubscribe();
        };
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getOrganisationUsers(organisation_id: number): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisation-users', {':id': organisation_id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  organisationDepartmentUsers(organisation_id: number, department_id: number, event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
            this.filters.organisationDepartmentUsers.isLoading = true;
          }
        }
        subscription = this.getOrganisationDepartmentUsers(organisation_id, department_id).subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
              this.filters.organisationDepartmentUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
                this.filters.organisationDepartmentUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getOrganisationDepartmentUsers(organisation_id, department_id).subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.organisationDepartmentUsers !== 'undefined') {
                  this.filters.organisationDepartmentUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getOrganisationDepartmentUsers(organisation_id: number, department_id: number): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.organisation-department-users', {
        ':organisation_id': organisation_id,
        ':department_id': department_id
      }), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  assignedOrganisationDepartments(event: 'start' | 'stop' = 'start'): Observable<Department[]> {
    if (event === 'start') {
      return new Observable<Department[]>((o: Subscriber<Department[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
            this.filters.assignedOrganisationDepartments.isLoading = true;
          }
        }
        subscription = this.getAssignedOrganisationDepartments().subscribe((data: Department[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
              this.filters.assignedOrganisationDepartments.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.departments.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.departments.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
                this.filters.assignedOrganisationDepartments.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAssignedOrganisationDepartments().subscribe((data: Department[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.assignedOrganisationDepartments !== 'undefined') {
                  this.filters.assignedOrganisationDepartments.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.departments.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.departments.changed');
      }
    }
  }

  getAssignedOrganisationDepartments(): Observable<Department[]> {
    return new Observable<Department[]>((o: Subscriber<Department[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-departments'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  assignedOrganisationUsers(event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
            this.filters.assignedOrganisationUsers.isLoading = true;
          }
        }
        subscription = this.getAssignedOrganisationUsers().subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
              this.filters.assignedOrganisationUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
                this.filters.assignedOrganisationUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAssignedOrganisationUsers().subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.assignedOrganisationUsers !== 'undefined') {
                  this.filters.assignedOrganisationUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getAssignedOrganisationUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  assignedOrganisationDepartmentUsers(department_id: number, event: 'start' | 'stop' = 'start'): Observable<User[]> {
    if (event === 'start') {
      return new Observable<User[]>((o: Subscriber<User[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.assignedOrganisationDepartmentUsers !== 'undefined') {
            this.filters.assignedOrganisationDepartmentUsers.isLoading = true;
          }
        }
        subscription = this.getAssignedOrganisationDepartmentUsers(department_id).subscribe((data: User[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.assignedOrganisationDepartmentUsers !== 'undefined') {
              this.filters.assignedOrganisationDepartmentUsers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.users.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.assignedOrganisationDepartmentUsers !== 'undefined') {
                this.filters.assignedOrganisationDepartmentUsers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAssignedOrganisationDepartmentUsers(department_id).subscribe((data: User[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.assignedOrganisationDepartmentUsers !== 'undefined') {
                  this.filters.assignedOrganisationDepartmentUsers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.users.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.users.changed');
      }
    }
  }

  getAssignedOrganisationDepartmentUsers(department_id: number): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.assigned-organisation-department-users', {':department_id': department_id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  availableMunicipalities(event: 'start' | 'stop' = 'start'): Observable<Municipality[]> {
    if (event === 'start') {
      return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.availableMunicipalities !== 'undefined') {
            this.filters.availableMunicipalities.isLoading = true;
          }
        }
        subscription = this.getAvailableMunicipalities().subscribe((data: Municipality[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.availableMunicipalities !== 'undefined') {
              this.filters.availableMunicipalities.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.municipalities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.municipalities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.availableMunicipalities !== 'undefined') {
                this.filters.availableMunicipalities.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAvailableMunicipalities().subscribe((data: Municipality[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.availableMunicipalities !== 'undefined') {
                  this.filters.availableMunicipalities.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.municipalities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.municipalities.changed');
      }
    }
  }

  getAvailableMunicipalities(): Observable<Municipality[]> {
    return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.available-municipalities'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  availableMunicipalitiesForDepartments(id: number): Observable<Municipality[]> {
    return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
      let subscription: Subscription = null;

      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.availableMunicipalitiesForDepartments !== 'undefined') {
          this.filters.availableMunicipalitiesForDepartments.isLoading = true;
        }
      }
      subscription = this.getAvailableMunicipalitiesForDepartments(id).subscribe((data: Municipality[]): void => {
        o.next(data);
        if (this.filters !== null) {
          if (typeof this.filters.availableMunicipalitiesForDepartments !== 'undefined') {
            this.filters.availableMunicipalitiesForDepartments.isLoading = false;
          }
        }
      });

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.municipalities.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.municipalities.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.availableMunicipalitiesForDepartments !== 'undefined') {
              this.filters.availableMunicipalitiesForDepartments.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getAvailableMunicipalitiesForDepartments(id).subscribe((data: Municipality[]): void => {
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.availableMunicipalitiesForDepartments !== 'undefined') {
                this.filters.availableMunicipalitiesForDepartments.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getAvailableMunicipalitiesForDepartments(id: number): Observable<Municipality[]> {
    return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.available-municipalities-departments', {':id': id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  provinces(event: 'start' | 'stop' = 'start'): Observable<Province[]> {
    if (event === 'start') {
      return new Observable<Province[]>((o: Subscriber<Province[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.provinces !== 'undefined') {
            this.filters.provinces.isLoading = true;
          }
        }
        subscription = this.getProvinces().subscribe((data: Province[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.provinces !== 'undefined') {
              this.filters.provinces.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.provinces.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.provinces.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.provinces !== 'undefined') {
                this.filters.provinces.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getProvinces().subscribe((data: Province[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.provinces !== 'undefined') {
                  this.filters.provinces.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.provinces.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.provinces.changed');
      }
    }
  }

  getProvinces(): Observable<Province[]> {
    return new Observable<Province[]>((o: Subscriber<Province[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.provinces'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  clientPortalAvailableProvinces(event: 'start' | 'stop' = 'start', id: number = null): Observable<Province[]> {
    if (event === 'start') {
      return new Observable<Province[]>((o: Subscriber<Province[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.clientPortalAvailableProvinces !== 'undefined') {
            this.filters.clientPortalAvailableProvinces.isLoading = true;
          }
        }
        subscription = this.getClientPortalAvailableProvinces(id).subscribe((data: Province[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.clientPortalAvailableProvinces !== 'undefined') {
              this.filters.clientPortalAvailableProvinces.isLoading = false;
            }
          }
        });

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.provinces.changed');
      }
    }
  }

  getClientPortalAvailableProvinces(id: number): Observable<Province[]> {
    return new Observable<Province[]>((o: Subscriber<Province[]>) => {
      const subscription: Subscription = this.api.get(
        (id !== null
            ? this.apiEndpoints.get('filters.client-portal-available-provinces-include', {':id': id})
            : this.apiEndpoints.get('filters.client-portal-available-provinces')
        ), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  municipalities(event: 'start' | 'stop' = 'start'): Observable<Municipality[]> {
    if (event === 'start') {
      return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.municipalities !== 'undefined') {
            this.filters.municipalities.isLoading = true;
          }
        }
        subscription = this.getMunicipalities().subscribe((data: Municipality[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.municipalities !== 'undefined') {
              this.filters.municipalities.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.municipalities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.municipalities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.municipalities !== 'undefined') {
                this.filters.municipalities.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMunicipalities().subscribe((data: Municipality[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.municipalities !== 'undefined') {
                  this.filters.municipalities.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.municipalities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.municipalities.changed');
      }
    }
  }

  getMunicipalities(): Observable<Municipality[]> {
    return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.municipalities'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  clientPortalAvailableMunicipalities(event: 'start' | 'stop' = 'start', id: number = null): Observable<Municipality[]> {
    if (event === 'start') {
      return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.clientPortalAvailableMunicipalities !== 'undefined') {
            this.filters.clientPortalAvailableMunicipalities.isLoading = true;
          }
        }
        subscription = this.getClientPortalAvailableMunicipalities(id).subscribe((data: Municipality[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.clientPortalAvailableMunicipalities !== 'undefined') {
              this.filters.clientPortalAvailableMunicipalities.isLoading = false;
            }
          }
        });

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.municipalities.changed');
      }
    }
  }

  getClientPortalAvailableMunicipalities(id: number): Observable<Municipality[]> {
    return new Observable<Municipality[]>((o: Subscriber<Municipality[]>) => {
      const subscription: Subscription = this.api.get(
        (id !== null
            ? this.apiEndpoints.get('filters.client-portal-available-municipalities-include', {':id': id})
            : this.apiEndpoints.get('filters.client-portal-available-municipalities')
        ), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  cities(event: 'start' | 'stop' = 'start'): Observable<City[]> {
    if (event === 'start') {
      return new Observable<City[]>((o: Subscriber<City[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.cities !== 'undefined') {
            this.filters.cities.isLoading = true;
          }
        }
        subscription = this.getCities().subscribe((data: City[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.cities !== 'undefined') {
              this.filters.cities.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.cities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.cities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.cities !== 'undefined') {
                this.filters.cities.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCities().subscribe((data: City[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.cities !== 'undefined') {
                  this.filters.cities.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.cities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.cities.changed');
      }
    }
  }

  getCities(): Observable<City[]> {
    return new Observable<City[]>((o: Subscriber<City[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.cities'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  city(city_id: number, event: 'start' | 'stop' = 'start'): Observable<City> {
    if (event === 'start') {
      return new Observable<City>((o: Subscriber<City>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.city !== 'undefined') {
            this.filters.city.isLoading = true;
          }
        }
        subscription = this.getCity(city_id).subscribe((data: City): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.city !== 'undefined') {
              this.filters.city.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.cities.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.cities.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.city !== 'undefined') {
                this.filters.city.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCity(city_id).subscribe((data: City): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.city !== 'undefined') {
                  this.filters.city.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.cities.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.cities.changed');
      }
    }
  }

  getCity(city_id: number): Observable<City> {
    return new Observable<City>((o: Subscriber<City>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.city', {':city_id': city_id}), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next(null);
          }
        },
        (): void => {
          o.next(null);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  municipalitiesAndProvinces(): Observable<Municipality[] | Province[]> {
    return new Observable<Municipality[] | Province[]>((o: Subscriber<Municipality[] | Province[]>) => {
      let subscription: Subscription = null;

      // get data from server
      if (this.filters !== null) {
        if (typeof this.filters.municipalitiesAndProvinces !== 'undefined') {
          this.filters.municipalitiesAndProvinces.isLoading = true;
        }
      }
      subscription = this.getMunicipalitiesAndProvinces().subscribe((data: Municipality[] | Province[]): void => {
        o.next(data);
        if (this.filters !== null) {
          if (typeof this.filters.municipalitiesAndProvinces !== 'undefined') {
            this.filters.municipalitiesAndProvinces.isLoading = false;
          }
        }
      });

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getMunicipalitiesAndProvinces(): Observable<Municipality[] | Province[]> {
    return new Observable<Municipality[] | Province[]>((o: Subscriber<Municipality[] | Province[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.municipalities-and-provinces'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  permissions(event: 'start' | 'stop' = 'start'): Observable<Permission[]> {
    if (event === 'start') {
      return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.permissions !== 'undefined') {
            this.filters.permissions.isLoading = true;
          }
        }
        subscription = this.getPermissions().subscribe((data: Permission[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.permissions !== 'undefined') {
              this.filters.permissions.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.permissions.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.permissions.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.permissions !== 'undefined') {
                this.filters.permissions.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getPermissions().subscribe((data: Permission[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.permissions !== 'undefined') {
                  this.filters.permissions.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.permissions.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.permissions.changed');
      }
    }
  }

  getPermissions(): Observable<Permission[]> {
    return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.permissions'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  permissionGroups(event: 'start' | 'stop' = 'start'): Observable<Permission[]> {
    if (event === 'start') {
      return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.permissionGroups !== 'undefined') {
            this.filters.permissionGroups.isLoading = true;
          }
        }
        subscription = this.getPermissionGroups().subscribe((data: Permission[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.permissionGroups !== 'undefined') {
              this.filters.permissionGroups.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.permission-groups.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.permission-groups.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.permissionGroups !== 'undefined') {
                this.filters.permissionGroups.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getPermissionGroups().subscribe((data: Permission[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.permissionGroups !== 'undefined') {
                  this.filters.permissionGroups.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.permission-groups.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.permission-groups.changed');
      }
    }
  }

  getPermissionGroups(): Observable<Permission[]> {
    return new Observable<Permission[]>((o: Subscriber<Permission[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.permission-groups'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next(null);
          }
        },
        (): void => {
          o.next(null);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  roles(event: 'start' | 'stop' = 'start'): Observable<Role[]> {
    if (event === 'start') {
      return new Observable<Role[]>((o: Subscriber<Role[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.roles !== 'undefined') {
            this.filters.roles.isLoading = true;
          }
        }
        subscription = this.getRoles().subscribe((data: Role[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.roles !== 'undefined') {
              this.filters.roles.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.roles.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.roles.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.roles !== 'undefined') {
                this.filters.roles.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getRoles().subscribe((data: Role[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.roles !== 'undefined') {
                  this.filters.roles.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.roles.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.roles.changed');
      }
    }
  }

  getRoles(): Observable<Role[]> {
    return new Observable<Role[]>((o: Subscriber<Role[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.roles'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  users(event: 'start' | 'stop' = 'start'): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.users.length) {
        o.next(this.filterData.users);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.users !== 'undefined') {
            this.filters.users.isLoading = true;
          }
        }
        subscription = this.getUsers().subscribe((data: User[]): void => {
          this.filterData.users = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.users !== 'undefined') {
              this.filters.users.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.users.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.users.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.users !== 'undefined') {
              this.filters.users.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getUsers().subscribe((data: User[]): void => {
            this.filterData.users = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.users !== 'undefined') {
                this.filters.users.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  archiveUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.archiveUsers.length) {
        o.next(this.filterData.archiveUsers);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.archiveUsers !== 'undefined') {
            this.filters.archiveUsers.isLoading = true;
          }
        }
        subscription = this.getArchiveUsers().subscribe((data: User[]): void => {
          this.filterData.archiveUsers = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.archiveUsers !== 'undefined') {
              this.filters.archiveUsers.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getArchiveUsers(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.users'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  archiveUsersAll(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.archiveUsersAll.length) {
        o.next(this.filterData.archiveUsersAll);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.archiveUsersAll !== 'undefined') {
            this.filters.archiveUsersAll.isLoading = true;
          }
        }
        subscription = this.getArchiveUsersAll().subscribe((data: User[]): void => {
          this.filterData.archiveUsersAll = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.archiveUsersAll !== 'undefined') {
              this.filters.archiveUsersAll.isLoading = false;
            }
          }
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getArchiveUsersAll(): Observable<User[]> {
    return new Observable<User[]>((o: Subscriber<User[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('archive.archivering.users.all'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  mailTypes(event: 'start' | 'stop' = 'start'): Observable<MailType[]> {
    if (event === 'start') {
      return new Observable<MailType[]>((o: Subscriber<MailType[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.mailTypes !== 'undefined') {
            this.filters.mailTypes.isLoading = true;
          }
        }
        subscription = this.getMailTypes().subscribe((data: MailType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.mailTypes !== 'undefined') {
              this.filters.mailTypes.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.mail-types.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.mail-types.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailTypes !== 'undefined') {
                this.filters.mailTypes.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailTypes().subscribe((data: MailType[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailTypes !== 'undefined') {
                  this.filters.mailTypes.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.mail-types.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.mail-types.changed');
      }
    }
  }

  getMailTypes(): Observable<MailType[]> {
    return new Observable<MailType[]>((o: Subscriber<MailType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.mail-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  historyTypes(event: 'start' | 'stop' = 'start'): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.historyTypes.length) {
        o.next(this.filterData.historyTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.historyTypes !== 'undefined') {
            this.filters.historyTypes.isLoading = true;
          }
        }
        subscription = this.getHistoryTypes().subscribe((data: ReportHistoryType[]): void => {
          this.filterData.historyTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.historyTypes !== 'undefined') {
              this.filters.historyTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-history-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-history-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.historyTypes !== 'undefined') {
              this.filters.historyTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getHistoryTypes().subscribe((data: ReportHistoryType[]): void => {
            this.filterData.historyTypes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.historyTypes !== 'undefined') {
                this.filters.historyTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getHistoryTypes(): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-history-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportProducts(event: 'start' | 'stop' = 'start'): Observable<ReportProduct[]> {
    return new Observable<ReportProduct[]>((o: Subscriber<ReportProduct[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportProducts.length) {
        o.next(this.filterData.reportProducts);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportProducts !== 'undefined') {
            this.filters.reportProducts.isLoading = true;
          }
        }
        subscription = this.getReportProducts().subscribe((data: ReportProduct[]): void => {
          this.filterData.reportProducts = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportProducts !== 'undefined') {
              this.filters.reportProducts.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-products.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-products.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportProducts !== 'undefined') {
              this.filters.reportProducts.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportProducts().subscribe((data: ReportProduct[]): void => {
            this.filterData.reportProducts = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportProducts !== 'undefined') {
                this.filters.reportProducts.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportProducts(): Observable<ReportProduct[]> {
    return new Observable<ReportProduct[]>((o: Subscriber<ReportProduct[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-products'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportSources(event: 'start' | 'stop' = 'start'): Observable<ReportSource[]> {
    return new Observable<ReportSource[]>((o: Subscriber<ReportSource[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportSources.length) {
        o.next(this.filterData.reportSources);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportSources !== 'undefined') {
            this.filters.reportSources.isLoading = true;
          }
        }
        subscription = this.getReportSources().subscribe((data: ReportSource[]): void => {
          this.filterData.reportSources = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportSources !== 'undefined') {
              this.filters.reportSources.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-sources.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-sources.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportSources !== 'undefined') {
              this.filters.reportSources.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportSources().subscribe((data: ReportSource[]): void => {
            this.filterData.reportSources = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportSources !== 'undefined') {
                this.filters.reportSources.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportSources(): Observable<ReportSource[]> {
    return new Observable<ReportSource[]>((o: Subscriber<ReportSource[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-sources'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportStatuses(event: 'start' | 'stop' = 'start'): Observable<ReportStatus[]> {
    return new Observable<ReportStatus[]>((o: Subscriber<ReportStatus[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportStatuses.length) {
        o.next(this.filterData.reportStatuses);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportStatuses !== 'undefined') {
            this.filters.reportStatuses.isLoading = true;
          }
        }
        subscription = this.getReportStatuses().subscribe((data: ReportStatus[]): void => {
          this.filterData.reportStatuses = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportStatuses !== 'undefined') {
              this.filters.reportStatuses.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-statuses.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-statuses.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportStatuses !== 'undefined') {
              this.filters.reportStatuses.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportStatuses().subscribe((data: ReportStatus[]): void => {
            this.filterData.reportStatuses = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportStatuses !== 'undefined') {
                this.filters.reportStatuses.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportStatuses(): Observable<ReportStatus[]> {
    return new Observable<ReportStatus[]>((o: Subscriber<ReportStatus[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-statuses'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportStatusCodes(event: 'start' | 'stop' = 'start'): Observable<ReportStatusCode[]> {
    return new Observable<ReportStatusCode[]>((o: Subscriber<ReportStatusCode[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportStatusCodes.length) {
        o.next(this.filterData.reportStatusCodes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportStatusCodes !== 'undefined') {
            this.filters.reportStatusCodes.isLoading = true;
          }
        }
        subscription = this.getReportStatusCodes().subscribe((data: ReportStatusCode[]): void => {
          this.filterData.reportStatusCodes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportStatusCodes !== 'undefined') {
              this.filters.reportStatusCodes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-status-codes.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-status-codes.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportStatusCodes !== 'undefined') {
              this.filters.reportStatusCodes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportStatusCodes().subscribe((data: ReportStatusCode[]): void => {
            this.filterData.reportStatusCodes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportStatusCodes !== 'undefined') {
                this.filters.reportStatusCodes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportStatusCodes(): Observable<ReportStatusCode[]> {
    return new Observable<ReportStatusCode[]>((o: Subscriber<ReportStatusCode[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-status-codes'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportCauserTypes(event: 'start' | 'stop' = 'start'): Observable<ReportCauserType[]> {
    return new Observable<ReportCauserType[]>((o: Subscriber<ReportCauserType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reportCauserTypes.length) {
        o.next(this.filterData.reportCauserTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportCauserTypes !== 'undefined') {
            this.filters.reportCauserTypes.isLoading = true;
          }
        }
        subscription = this.getReportCauserTypes().subscribe((data: ReportCauserType[]): void => {
          this.filterData.reportCauserTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportCauserTypes !== 'undefined') {
              this.filters.reportCauserTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-causer-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-causer-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reportCauserTypes !== 'undefined') {
              this.filters.reportCauserTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReportCauserTypes().subscribe((data: ReportCauserType[]): void => {
            this.filterData.reportCauserTypes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reportCauserTypes !== 'undefined') {
                this.filters.reportCauserTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReportCauserTypes(): Observable<ReportCauserType[]> {
    return new Observable<ReportCauserType[]>((o: Subscriber<ReportCauserType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-causer-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  reporterTypes(event: 'start' | 'stop' = 'start'): Observable<ReporterType[]> {
    return new Observable<ReporterType[]>((o: Subscriber<ReporterType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reporterTypes.length) {
        o.next(this.filterData.reporterTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reporterTypes !== 'undefined') {
            this.filters.reporterTypes.isLoading = true;
          }
        }
        subscription = this.getReporterTypes().subscribe((data: ReporterType[]): void => {
          this.filterData.reporterTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reporterTypes !== 'undefined') {
              this.filters.reporterTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.reporter-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.reporter-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reporterTypes !== 'undefined') {
              this.filters.reporterTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReporterTypes().subscribe((data: ReporterType[]): void => {
            this.filterData.reporterTypes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reporterTypes !== 'undefined') {
                this.filters.reporterTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReporterTypes(): Observable<ReporterType[]> {
    return new Observable<ReporterType[]>((o: Subscriber<ReporterType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.reporter-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reporterContactTimeframes(event: 'start' | 'stop' = 'start'): Observable<ReporterContactTimeframe[]> {
    return new Observable<ReporterContactTimeframe[]>((o: Subscriber<ReporterContactTimeframe[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.reporterContactTimeframes.length) {
        o.next(this.filterData.reporterContactTimeframes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reporterContactTimeframes !== 'undefined') {
            this.filters.reporterContactTimeframes.isLoading = true;
          }
        }
        subscription = this.getReporterContactTimeframes().subscribe((data: ReporterContactTimeframe[]): void => {
          this.filterData.reporterContactTimeframes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reporterContactTimeframes !== 'undefined') {
              this.filters.reporterContactTimeframes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.reporter-contact-timeframes.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.reporter-contact-timeframes.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.reporterContactTimeframes !== 'undefined') {
              this.filters.reporterContactTimeframes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getReporterContactTimeframes().subscribe((data: ReporterContactTimeframe[]): void => {
            this.filterData.reporterContactTimeframes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.reporterContactTimeframes !== 'undefined') {
                this.filters.reporterContactTimeframes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getReporterContactTimeframes(): Observable<ReporterContactTimeframe[]> {
    return new Observable<ReporterContactTimeframe[]>((o: Subscriber<ReporterContactTimeframe[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.reporter-contact-timeframes'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  headCategoriesTypes(event: 'start' | 'stop' = 'start'): Observable<HeadCategoriesTypes[]> {
    return new Observable<HeadCategoriesTypes[]>((o: Subscriber<HeadCategoriesTypes[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.headCategoriesTypes.length) {
        o.next(this.filterData.headCategoriesTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.headCategoriesTypes !== 'undefined') {
            this.filters.headCategoriesTypes.isLoading = true;
          }
        }
        subscription = this.getHeadCategoriesTypes().subscribe((data: HeadCategoriesTypes[]): void => {
          this.filterData.headCategoriesTypes = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.headCategoriesTypes !== 'undefined') {
              this.filters.headCategoriesTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.head-categories-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.head-categories-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.headCategoriesTypes !== 'undefined') {
              this.filters.headCategoriesTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getHeadCategoriesTypes().subscribe((data: HeadCategoriesTypes[]): void => {
            this.filterData.headCategoriesTypes = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.headCategoriesTypes !== 'undefined') {
                this.filters.headCategoriesTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getHeadCategoriesTypes(): Observable<HeadCategoriesTypes[]> {
    return new Observable<HeadCategoriesTypes[]>((o: Subscriber<HeadCategoriesTypes[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.head-categories-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  headCategories(surroundingId: number = null, event: 'start' | 'stop' = 'start'): Observable<HeadCategory[]> {
    return new Observable<HeadCategory[]>((o: Subscriber<HeadCategory[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (typeof this.filterData.headCategories[surroundingId] !== 'undefined' && this.filterData.headCategories[surroundingId].length) {
        o.next(this.filterData.headCategories[surroundingId]);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.headCategories !== 'undefined') {
            this.filters.headCategories.isLoading = true;
          }
        }
        subscription = this.getHeadCategories(surroundingId).subscribe((data: HeadCategory[]): void => {
          this.filterData.headCategories[surroundingId] = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.headCategories !== 'undefined') {
              this.filters.headCategories.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.head-categories.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.head-categories.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.headCategories !== 'undefined') {
              this.filters.headCategories.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getHeadCategories(surroundingId).subscribe((data: HeadCategory[]): void => {
            this.filterData.headCategories[surroundingId] = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.headCategories !== 'undefined') {
                this.filters.headCategories.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getHeadCategories(surroundingId: number = null): Observable<HeadCategory[]> {
    return new Observable<HeadCategory[]>((o: Subscriber<HeadCategory[]>) => {
      const subscription: Subscription = this.api.get((
        surroundingId !== null
          ? this.apiEndpoints.get('filters.head-categories-for-surrounding', {':id': surroundingId})
          : this.apiEndpoints.get('filters.head-categories')
      ), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  mainCategoriesSurroundings(event: 'start' | 'stop' = 'start'): Observable<MainCategoriesSurroundings[]> {
    return new Observable<MainCategoriesSurroundings[]>((o: Subscriber<MainCategoriesSurroundings[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.mainCategoriesSurroundings.length) {
        o.next(this.filterData.mainCategoriesSurroundings);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
            this.filters.mainCategoriesSurroundings.isLoading = true;
          }
        }
        subscription = this.getMainCategoriesSurroundings().subscribe((data: MainCategoriesSurroundings[]): void => {
          this.filterData.mainCategoriesSurroundings = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
              this.filters.mainCategoriesSurroundings.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.main-categories-surroundings.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.main-categories-surroundings.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
              this.filters.mainCategoriesSurroundings.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getMainCategoriesSurroundings().subscribe((data: MainCategoriesSurroundings[]): void => {
            this.filterData.mainCategoriesSurroundings = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mainCategoriesSurroundings !== 'undefined') {
                this.filters.mainCategoriesSurroundings.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getMainCategoriesSurroundings(): Observable<MainCategoriesSurroundings[]> {
    return new Observable<MainCategoriesSurroundings[]>((o: Subscriber<MainCategoriesSurroundings[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.main-categories-surroundings'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  mainCategories(head_category_id: number = null, surroundingId: number = null, event: 'start' | 'stop' = 'start'): Observable<MainCategory[]> {
    return new Observable<MainCategory[]>((o: Subscriber<MainCategory[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (
        head_category_id === null && typeof this.filterData.mainCategories['null'] !== 'undefined' && this.filterData.mainCategories['null'].length ||
        head_category_id !== null && surroundingId === null && typeof this.filterData.mainCategories[head_category_id] !== 'undefined' && this.filterData.mainCategories[head_category_id].length ||
        head_category_id !== null && surroundingId !== null &&
        typeof this.filterData.mainCategories['surroundings'] !== 'undefined' &&
        typeof this.filterData.mainCategories['surroundings'][head_category_id] !== 'undefined' &&
        typeof this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] !== 'undefined' &&
        this.filterData.mainCategories['surroundings'][head_category_id][surroundingId].length
      ) {
        if (head_category_id === null) {
          o.next(this.filterData.mainCategories['null']);
        } else if (surroundingId !== null) {
          o.next(this.filterData.mainCategories['surroundings'][head_category_id][surroundingId]);
        } else {
          o.next(this.filterData.mainCategories[head_category_id]);
        }
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.mainCategories !== 'undefined') {
            this.filters.mainCategories.isLoading = true;
          }
        }
        subscription = this.getMainCategories(head_category_id, surroundingId).subscribe((data: MainCategory[]): void => {
          if (surroundingId !== null) {
            if (typeof this.filterData.mainCategories['surroundings'] === 'undefined') {
              this.filterData.mainCategories['surroundings'] = [];
            }

            if (typeof this.filterData.mainCategories['surroundings'][head_category_id] === 'undefined') {
              this.filterData.mainCategories['surroundings'][head_category_id] = [];
            }

            if (typeof this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] === 'undefined') {
              this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] = [];
            }
            this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] = data;
          } else if (head_category_id !== null) {
            this.filterData.mainCategories[head_category_id] = data;
          } else {
            this.filterData.mainCategories['null'] = data;
          }
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.mainCategories !== 'undefined') {
              this.filters.mainCategories.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.main-categories.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.main-categories.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.mainCategories !== 'undefined') {
              this.filters.mainCategories.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getMainCategories(head_category_id, surroundingId).subscribe((data: MainCategory[]): void => {
            if (surroundingId !== null) {
              if (typeof this.filterData.mainCategories['surroundings'] === 'undefined') {
                this.filterData.mainCategories['surroundings'] = [];
              }

              if (typeof this.filterData.mainCategories['surroundings'][head_category_id] === 'undefined') {
                this.filterData.mainCategories['surroundings'][head_category_id] = [];
              }

              if (typeof this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] === 'undefined') {
                this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] = [];
              }
              this.filterData.mainCategories['surroundings'][head_category_id][surroundingId] = data;
            } else if (head_category_id !== null) {
              this.filterData.mainCategories[head_category_id] = data;
            } else {
              this.filterData.mainCategories['null'] = data;
            }
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mainCategories !== 'undefined') {
                this.filters.mainCategories.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getMainCategories(head_category_id: number = null, surroundingId: number = null): Observable<MainCategory[]> {
    return new Observable<MainCategory[]>((o: Subscriber<MainCategory[]>) => {
      const subscription: Subscription = this.api.get((
        (head_category_id !== null ? (
          (surroundingId !== null
              ? this.apiEndpoints.get('filters.head-categories.main-categories-for-surrounding', {
                ':head_category_id': head_category_id,
                ':id': surroundingId
              })
              : this.apiEndpoints.get('filters.head-categories.main-categories', {':head_category_id': head_category_id})
          )
        ) : this.apiEndpoints.get('filters.main-categories'))
      ), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  subCategories(head_category_id: number, main_category_id: number = null, event: 'start' | 'stop' = 'start'): Observable<SubCategory[]> {
    return new Observable<SubCategory[]>((o: Subscriber<SubCategory[]>) => {
      let subscription: Subscription = null;

      if (main_category_id === null && typeof this.filterData.subCategories['null'] !== 'undefined' && this.filterData.subCategories['null'].length ||
        main_category_id !== null && typeof this.filterData.subCategories[main_category_id] !== 'undefined' && this.filterData.subCategories[main_category_id].length) {
        if (main_category_id !== null) {
          o.next(this.filterData.subCategories[main_category_id]);
        } else {
          o.next(this.filterData.subCategories['null']);
        }
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.subCategories !== 'undefined') {
            this.filters.subCategories.isLoading = true;
          }
        }

        subscription = this.getSubCategories(head_category_id, main_category_id).subscribe((data: SubCategory[]): void => {
          if (main_category_id !== null) {
            this.filterData.subCategories[main_category_id] = data;
          } else {
            this.filterData.subCategories['null'] = data;
          }
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.subCategories !== 'undefined') {
              this.filters.subCategories.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.sub-categories.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.sub-categories.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.subCategories !== 'undefined') {
              this.filters.subCategories.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getSubCategories(head_category_id, main_category_id).subscribe((data: SubCategory[]): void => {
            if (main_category_id !== null) {
              this.filterData.subCategories[main_category_id] = data;
            } else {
              this.filterData.subCategories['null'] = data;
            }
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.subCategories !== 'undefined') {
                this.filters.subCategories.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getSubCategories(head_category_id: number, main_category_id: number = null): Observable<SubCategory[]> {
    return new Observable<SubCategory[]>((o: Subscriber<SubCategory[]>) => {
      const subscription: Subscription = this.api.get((
        main_category_id
          ? this.apiEndpoints.get('filters.head-categories.main-categories.sub-categories', {
            ':head_category_id': head_category_id,
            ':main_category_id': main_category_id
          })
          : this.apiEndpoints.get('filters.sub-categories')
      ), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  companies(search: string, event: 'start' | 'stop' = 'start'): Observable<Company[]> {
    if (event === 'start') {
      return new Observable<Company[]>((o: Subscriber<Company[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.companies !== 'undefined') {
            this.filters.companies.isLoading = true;
          }
        }
        const subscription: Subscription = this.getCompanies(search).subscribe((data: Company[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.companies !== 'undefined') {
              this.filters.companies.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.companies.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.companies.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.companies !== 'undefined') {
                this.filters.companies.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCompanies(search).subscribe((data: Company[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.companies !== 'undefined') {
                  this.filters.companies.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.companies.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.companies.changed');
      }
    }
  }

  getCompanies(search: string): Observable<Company[]> {
    return new Observable<Company[]>((o: Subscriber<Company[]>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('filters.companies'), {search: search})
        .subscribe(
          (response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(response.data);
            } else {
              o.next([]);
            }
          }, (): void => {
            o.next([]);
          },
          (): void => o.complete());

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  companiesAssigned(search: string, event: 'start' | 'stop' = 'start'): Observable<Company[]> {
    if (event === 'start') {
      return new Observable<Company[]>((o: Subscriber<Company[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.companies !== 'undefined') {
            this.filters.companies.isLoading = true;
          }
        }
        const subscription: Subscription = this.getCompaniesAssigned(search).subscribe((data: Company[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.companies !== 'undefined') {
              this.filters.companies.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.companies.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.companies.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.companies !== 'undefined') {
                this.filters.companies.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCompaniesAssigned(search).subscribe((data: Company[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.companies !== 'undefined') {
                  this.filters.companies.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.companies.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.companies.changed');
      }
    }
  }

  getCompaniesAssigned(search: string): Observable<Company[]> {
    return new Observable<Company[]>((o: Subscriber<Company[]>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('filters.companies-assigned.search'), {search: search})
        .subscribe(
          (response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(response.data);
            } else {
              o.next([]);
            }
          }, (): void => {
            o.next([]);
          },
          (): void => o.complete());

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  companyOptions(event: 'start' | 'stop' = 'start'): Observable<CompanyOption[]> {
    return new Observable<CompanyOption[]>((o: Subscriber<CompanyOption[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.companyOptions.length) {
        o.next(this.filterData.companyOptions);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.companyOptions !== 'undefined') {
            this.filters.companyOptions.isLoading = true;
          }
        }
        subscription = this.getCompanyOptions().subscribe((data: CompanyOption[]): void => {
          o.next(data);
          this.filterData.companyOptions = data;
          if (this.filters !== null) {
            if (typeof this.filters.companyOptions !== 'undefined') {
              this.filters.companyOptions.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.company-options.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.company-options.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.companyOptions !== 'undefined') {
              this.filters.companyOptions.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getCompanyOptions().subscribe((data: CompanyOption[]): void => {
            o.next(data);
            this.filterData.companyOptions = data;
            if (this.filters !== null) {
              if (typeof this.filters.companyOptions !== 'undefined') {
                this.filters.companyOptions.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getCompanyOptions(): Observable<CompanyOption[]> {
    return new Observable<CompanyOption[]>((o: Subscriber<CompanyOption[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.company-options'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  companiesWithTypes(search: string, event: 'start' | 'stop' = 'start'): Observable<Company[]> {
    if (event === 'start') {
      return new Observable<Company[]>((o: Subscriber<Company[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.companies !== 'undefined') {
            this.filters.companies.isLoading = true;
          }
        }
        const subscription: Subscription = this.getCompaniesWithTypes(search).subscribe((data: Company[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.companies !== 'undefined') {
              this.filters.companies.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.companies.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.companies.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.companies !== 'undefined') {
                this.filters.companies.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getCompaniesWithTypes(search).subscribe((data: Company[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.companies !== 'undefined') {
                  this.filters.companies.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.companies.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.companies.changed');
      }
    }
  }

  getCompaniesWithTypes(search: string): Observable<Company[]> {
    return new Observable<Company[]>((o: Subscriber<Company[]>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('filters.companies-with-types'), {search: search})
        .subscribe(
          (response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(response.data);
            } else {
              o.next([]);
            }
          }, (): void => {
            o.next([]);
          },
          (): void => o.complete());

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  addressBook(event: 'start' | 'stop' = 'start'): Observable<AddressBook[]> {
    if (event === 'start') {
      return new Observable<AddressBook[]>((o: Subscriber<AddressBook[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.addressBook.length) {
          o.next(this.filterData.addressBook);
        } else {
          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.addressBook !== 'undefined') {
              this.filters.addressBook.isLoading = true;
            }
          }
          subscription = this.getAddressBooks().subscribe((data: AddressBook[]): void => {
            o.next(data);
            this.filterData.addressBook = data;
            if (this.filters !== null) {
              if (typeof this.filters.addressBook !== 'undefined') {
                this.filters.addressBook.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.address-books.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.address-books.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.addressBook !== 'undefined') {
                this.filters.addressBook.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAddressBooks().subscribe((data: AddressBook[]): void => {
              o.next(data);
              this.filterData.addressBook = data;
              if (this.filters !== null) {
                if (typeof this.filters.addressBook !== 'undefined') {
                  this.filters.addressBook.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.address-books.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.address-books.changed');
      }
    }
  }

  getAddressBooks(): Observable<AddressBook[]> {
    return new Observable<AddressBook[]>((o: Subscriber<AddressBook[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.address-book'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  addressBookTypes(event: 'start' | 'stop' = 'start'): Observable<AddressBookType[]> {
    if (event === 'start') {
      return new Observable<AddressBookType[]>((o: Subscriber<AddressBookType[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.addressBookTypes !== 'undefined') {
            this.filters.addressBookTypes.isLoading = true;
          }
        }
        subscription = this.getAddressBookTypes().subscribe((data: AddressBookType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.addressBookTypes !== 'undefined') {
              this.filters.addressBookTypes.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.address-book-types.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.address-book-types.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.addressBookTypes !== 'undefined') {
                this.filters.addressBookTypes.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getAddressBookTypes().subscribe((data: AddressBookType[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.addressBookTypes !== 'undefined') {
                  this.filters.addressBookTypes.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.address-book-types.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.address-book-types.changed');
      }
    }
  }

  getAddressBookTypes(): Observable<AddressBookType[]> {
    return new Observable<AddressBookType[]>((o: Subscriber<AddressBookType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.address-book-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  standardReactions(event: 'start' | 'stop' = 'start'): Observable<StandardReaction[]> {
    if (event === 'start') {
      return new Observable<StandardReaction[]>((o: Subscriber<StandardReaction[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.standardReactions.length) {
          o.next(this.filterData.standardReactions);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.standardReactions !== 'undefined') {
              this.filters.standardReactions.isLoading = true;
            }
          }
          subscription = this.getStandardReactions().subscribe((data: StandardReaction[]): void => {
            this.filterData.standardReactions = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.standardReactions !== 'undefined') {
                this.filters.standardReactions.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.standard-reactions.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.standard-reactions.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.standardReactions !== 'undefined') {
                this.filters.standardReactions.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getStandardReactions().subscribe((data: StandardReaction[]): void => {
              this.filterData.standardReactions = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.standardReactions !== 'undefined') {
                  this.filters.standardReactions.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.standard-reactions.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.standard-reactions.changed');
      }
    }
  }

  getStandardReactions(): Observable<StandardReaction[]> {
    return new Observable<StandardReaction[]>((o: Subscriber<StandardReaction[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.standard-reactions'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  historyEntries(event: 'start' | 'stop' = 'start'): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.historyEntries.length) {
        o.next(this.filterData.historyEntries);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.historyEntries !== 'undefined') {
            this.filters.historyEntries.isLoading = true;
          }
        }
        subscription = this.getHistoryEntries().subscribe((data: ReportHistoryType[]): void => {
          this.filterData.historyEntries = data;
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.historyEntries !== 'undefined') {
              this.filters.historyEntries.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.report-history-entries.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.report-history-entries.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.historyEntries !== 'undefined') {
              this.filters.historyEntries.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getHistoryEntries().subscribe((data: ReportHistoryType[]): void => {
            this.filterData.historyEntries = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.historyEntries !== 'undefined') {
                this.filters.historyEntries.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getHistoryEntries(): Observable<ReportHistoryType[]> {
    return new Observable<ReportHistoryType[]>((o: Subscriber<ReportHistoryType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.report-history'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  publicNotificationTypes(event: 'start' | 'stop' = 'start'): Observable<PublicNotificationType[]> {
    if (event === 'start') {
      return new Observable<PublicNotificationType[]>((o: Subscriber<PublicNotificationType[]>) => {
        let subscription: Subscription = null;

        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.publicNotificationTypes !== 'undefined') {
            this.filters.publicNotificationTypes.isLoading = true;
          }
        }
        subscription = this.getPublicNotificationTypes().subscribe((data: PublicNotificationType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.publicNotificationTypes !== 'undefined') {
              this.filters.publicNotificationTypes.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.public-notification-types.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.public-notification-types.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.publicNotificationTypes !== 'undefined') {
                this.filters.publicNotificationTypes.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getPublicNotificationTypes().subscribe((data: PublicNotificationType[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.publicNotificationTypes !== 'undefined') {
                  this.filters.publicNotificationTypes.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.public-notification-types.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.public-notification-types.changed');
      }
    }
  }

  getPublicNotificationTypes(): Observable<PublicNotificationType[]> {
    return new Observable<PublicNotificationType[]>((o: Subscriber<PublicNotificationType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.public-notification-types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  unusualIncidentCauses(event: 'start' | 'stop' = 'start'): Observable<UnusualIncidentCause[]> {
    return new Observable<UnusualIncidentCause[]>((o: Subscriber<UnusualIncidentCause[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.unusualIncidentCauses.length) {
        o.next(this.filterData.unusualIncidentCauses);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.unusualIncidentCauses !== 'undefined') {
            this.filters.unusualIncidentCauses.isLoading = true;
          }
        }
        subscription = this.getUnusualIncidentCauses().subscribe((data: UnusualIncidentCause[]): void => {
          o.next(data);
          this.filterData.unusualIncidentCauses = data;
          if (this.filters !== null) {
            if (typeof this.filters.unusualIncidentCauses !== 'undefined') {
              this.filters.unusualIncidentCauses.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.unusual-incident-causes.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.unusual-incident-causes.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.unusualIncidentCauses !== 'undefined') {
              this.filters.unusualIncidentCauses.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getUnusualIncidentCauses().subscribe((data: UnusualIncidentCause[]): void => {
            o.next(data);
            this.filterData.unusualIncidentCauses = data;
            if (this.filters !== null) {
              if (typeof this.filters.unusualIncidentCauses !== 'undefined') {
                this.filters.unusualIncidentCauses.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getUnusualIncidentCauses(): Observable<UnusualIncidentCause[]> {
    return new Observable<UnusualIncidentCause[]>((o: Subscriber<UnusualIncidentCause[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.unusual-incident-causes'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  unusualIncidentConsequences(event: 'start' | 'stop' = 'start'): Observable<UnusualIncidentConsequence[]> {
    return new Observable<UnusualIncidentConsequence[]>((o: Subscriber<UnusualIncidentConsequence[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.unusualIncidentConsequences.length) {
        o.next(this.filterData.unusualIncidentConsequences);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.unusualIncidentConsequences !== 'undefined') {
            this.filters.unusualIncidentConsequences.isLoading = true;
          }
        }
        subscription = this.getUnusualIncidentConsequences().subscribe((data: UnusualIncidentConsequence[]): void => {
          o.next(data);
          this.filterData.unusualIncidentConsequences = data;
          if (this.filters !== null) {
            if (typeof this.filters.unusualIncidentConsequences !== 'undefined') {
              this.filters.unusualIncidentConsequences.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.unusual-incident-consequences.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.unusual-incident-consequences.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.unusualIncidentConsequences !== 'undefined') {
              this.filters.unusualIncidentConsequences.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getUnusualIncidentConsequences().subscribe((data: UnusualIncidentConsequence[]): void => {
            o.next(data);
            this.filterData.unusualIncidentConsequences = data;
            if (this.filters !== null) {
              if (typeof this.filters.unusualIncidentConsequences !== 'undefined') {
                this.filters.unusualIncidentConsequences.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getUnusualIncidentConsequences(): Observable<UnusualIncidentConsequence[]> {
    return new Observable<UnusualIncidentConsequence[]>((o: Subscriber<UnusualIncidentConsequence[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.unusual-incident-consequences'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  changelogTypes(event: 'start' | 'stop' = 'start'): Observable<ChangelogType[]> {
    return new Observable<ChangelogType[]>((o: Subscriber<ChangelogType[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.changelogTypes.length) {
        o.next(this.filterData.changelogTypes);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.changelogTypes !== 'undefined') {
            this.filters.changelogTypes.isLoading = true;
          }
        }
        subscription = this.getChangelogTypes().subscribe((data: ChangelogType[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.changelogTypes !== 'undefined') {
              this.filters.changelogTypes.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.changelog-types.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.changelog-types.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.changelogTypes !== 'undefined') {
              this.filters.changelogTypes.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getChangelogTypes().subscribe((data: ChangelogType[]): void => {
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.changelogTypes !== 'undefined') {
                this.filters.changelogTypes.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getChangelogTypes(): Observable<ChangelogType[]> {
    return new Observable<ChangelogType[]>((o: Subscriber<ChangelogType[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.knowledge-base.changelogs.types'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  contentVisibility(): Observable<ContentVisibility[]> {
    return new Observable<ContentVisibility[]>((o: Subscriber<ContentVisibility[]>) => {
      let subscription: Subscription = null;

      // check if we have cached data available
      if (this.filterData.contentVisibility.length) {
        o.next(this.filterData.contentVisibility);
      } else {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.contentVisibility !== 'undefined') {
            this.filters.contentVisibility.isLoading = true;
          }
        }
        subscription = this.getContentVisibility().subscribe((data: ContentVisibility[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.contentVisibility !== 'undefined') {
              this.filters.contentVisibility.isLoading = false;
            }
          }
        });
      }

      // check for events
      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').listen('.content-visibility.changed', () => {
          if (!environment.production) {
            console.log('WebSocket: received event for "filters" channel: ' + '.content-visibility.changed');
          }

          if (this.filters !== null) {
            if (typeof this.filters.contentVisibility !== 'undefined') {
              this.filters.contentVisibility.isLoading = true;
            }
          }
          const subscription2: Subscription = this.getContentVisibility().subscribe((data: ContentVisibility[]): void => {
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.contentVisibility !== 'undefined') {
                this.filters.contentVisibility.isLoading = false;
              }
            }
          });

          return (): void => {
            subscription2.unsubscribe();
          };
        });
      }

      if (subscription !== null) {
        return (): void => {
          subscription.unsubscribe();
        };
      }
    });
  }

  getContentVisibility(): Observable<ContentVisibility[]> {
    return new Observable<ContentVisibility[]>((o: Subscriber<ContentVisibility[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.content-visibility'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  reportNumbers(search: string, event: 'start' | 'stop' = 'start'): Observable<Report[]> {
    if (event === 'start') {
      return new Observable<Report[]>((o: Subscriber<Report[]>) => {
        // get data from server
        if (this.filters !== null) {
          if (typeof this.filters.reportNumbers !== 'undefined') {
            this.filters.reportNumbers.isLoading = true;
          }
        }
        const subscription: Subscription = this.getReportNumbers(search).subscribe((data: Report[]): void => {
          o.next(data);
          if (this.filters !== null) {
            if (typeof this.filters.reportNumbers !== 'undefined') {
              this.filters.reportNumbers.isLoading = false;
            }
          }
        });

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.reports.changed', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.companies.changed');
            }

            if (this.filters !== null) {
              if (typeof this.filters.reportNumbers !== 'undefined') {
                this.filters.reportNumbers.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getReportNumbers(search).subscribe((data: Report[]): void => {
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.reportNumbers !== 'undefined') {
                  this.filters.reportNumbers.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.reports.changed');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.reports.changed');
      }
    }
  }

  getReportNumbers(search: string): Observable<Report[]> {
    return new Observable<Report[]>((o: Subscriber<Report[]>) => {
      const subscription: Subscription = this.api.post(this.apiEndpoints.get('filters.report-numbers'), {search: search})
        .subscribe(
          (response: ServerResponse): void => {
            if (typeof response.data !== 'undefined') {
              o.next(response.data);
            } else {
              o.next([]);
            }
          }, (): void => {
            o.next([]);
          },
          (): void => o.complete());

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }


  mailEvents(event: 'start' | 'stop' = 'start'): Observable<MailEvent[]> {
    if (event === 'start') {
      return new Observable<MailEvent[]>((o: Subscriber<MailEvent[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.mailEvents.length) {
          o.next(this.filterData.mailEvents);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.mailEvents !== 'undefined') {
              this.filters.mailEvents.isLoading = true;
            }
          }
          subscription = this.getMailEvents().subscribe((data: MailEvent[]): void => {
            this.filterData.mailEvents = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mailEvents !== 'undefined') {
                this.filters.mailEvents.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sent-emails.events', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sent-emails.events');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailEvents !== 'undefined') {
                this.filters.mailEvents.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailEvents().subscribe((data: MailEvent[]): void => {
              this.filterData.mailEvents = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailEvents !== 'undefined') {
                  this.filters.mailEvents.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sent-emails.events');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sent-emails.events');
      }
    }
  }

  getMailEvents(): Observable<MailEvent[]> {
    return new Observable<MailEvent[]>((o: Subscriber<MailEvent[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.sent-emails.events'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  mailLogLevels(event: 'start' | 'stop' = 'start'): Observable<MailLogLevel[]> {
    if (event === 'start') {
      return new Observable<MailLogLevel[]>((o: Subscriber<MailLogLevel[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.mailLogLevels.length) {
          o.next(this.filterData.mailLogLevels);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.mailLogLevels !== 'undefined') {
              this.filters.mailLogLevels.isLoading = true;
            }
          }
          subscription = this.getMailLogLevels().subscribe((data: MailLogLevel[]): void => {
            this.filterData.mailLogLevels = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mailLogLevels !== 'undefined') {
                this.filters.mailLogLevels.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sent-emails.log-levels', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sent-emails.log-levels');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailLogLevels !== 'undefined') {
                this.filters.mailLogLevels.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailLogLevels().subscribe((data: MailLogLevel[]): void => {
              this.filterData.mailLogLevels = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailLogLevels !== 'undefined') {
                  this.filters.mailLogLevels.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sent-emails.log-levels');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sent-emails.log-levels');
      }
    }
  }

  getMailLogLevels(): Observable<MailLogLevel[]> {
    return new Observable<MailLogLevel[]>((o: Subscriber<MailLogLevel[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.sent-emails.log-levels'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  mailTags(event: 'start' | 'stop' = 'start'): Observable<MailTag[]> {
    if (event === 'start') {
      return new Observable<MailTag[]>((o: Subscriber<MailTag[]>) => {
        let subscription: Subscription = null;

        // check if we have cached data available
        if (this.filterData.mailTags.length) {
          o.next(this.filterData.mailTags);
        } else {

          // get data from server
          if (this.filters !== null) {
            if (typeof this.filters.mailTags !== 'undefined') {
              this.filters.mailTags.isLoading = true;
            }
          }
          subscription = this.getMailTags().subscribe((data: MailTag[]): void => {
            this.filterData.mailTags = data;
            o.next(data);
            if (this.filters !== null) {
              if (typeof this.filters.mailTags !== 'undefined') {
                this.filters.mailTags.isLoading = false;
              }
            }
          });
        }

        // check for events
        if (typeof window.Echo !== 'undefined') {
          window.Echo.private('filters').listen('.sent-emails.tags', () => {
            if (!environment.production) {
              console.log('WebSocket: received event for "filters" channel: ' + '.sent-emails.tags');
            }

            if (this.filters !== null) {
              if (typeof this.filters.mailTags !== 'undefined') {
                this.filters.mailTags.isLoading = true;
              }
            }
            const subscription2: Subscription = this.getMailTags().subscribe((data: MailTag[]): void => {
              this.filterData.mailTags = data;
              o.next(data);
              if (this.filters !== null) {
                if (typeof this.filters.mailTags !== 'undefined') {
                  this.filters.mailTags.isLoading = false;
                }
              }
            });

            return (): void => {
              subscription2.unsubscribe();
            };
          });
        }

        if (subscription !== null) {
          return (): void => {
            subscription.unsubscribe();
          };
        }
      });
    } else {
      if (!environment.production) {
        console.log('WebSocket: stopped listening event for "filters" channel: ' + '.sent-emails.tags');
      }

      if (typeof window.Echo !== 'undefined') {
        window.Echo.private('filters').stopListening('.sent-emails.tags');
      }
    }
  }

  getMailTags(): Observable<MailTag[]> {
    return new Observable<MailTag[]>((o: Subscriber<MailTag[]>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('filters.sent-emails.tags'), null, true).subscribe(
        (response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(response.data);
          } else {
            o.next([]);
          }
        },
        (): void => {
          o.next([]);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

}

import { Injectable } from '@angular/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { ApiService } from '../base/api.service';
import { ServerResponse } from '../../interfaces/base/server.response';
import { ApiEndpointsService } from '../base/api-endpoints.service';
import { MonthlyStatistic } from '../../interfaces/statistics/monthly-statistic';
import { SourcesStatistic } from '../../interfaces/statistics/sources-statistic';
import { PeriodStatistic } from '../../interfaces/statistics/period-statistic';
import { MkcReports } from '../../interfaces/statistics/mkc-reports';
import { SolidDigitalLicenceReport } from '../../interfaces/statistics/solid-digital-licence-report';

@Injectable()

export class StatisticsService {

  constructor(private api: ApiService,
              private apiEndpoints: ApiEndpointsService,
  ) {
  }

  mkcReports(): Observable<MkcReports | boolean> {
    return new Observable<MkcReports | boolean>((o: Subscriber<MkcReports | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.mkc-reports'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MkcReports>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportMkcReports(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.mkc-reports-download'));
  }

  categoriesPerMunicipality(): Observable<MonthlyStatistic | boolean> {
    return new Observable<MonthlyStatistic | boolean>((o: Subscriber<MonthlyStatistic | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.categories-per-municipality'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<MonthlyStatistic>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportCategoriesPerMunicipality(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.categories-per-municipality-download'));
  }

  categoriesPerPeriod(): Observable<PeriodStatistic | boolean> {
    return new Observable<PeriodStatistic | boolean>((o: Subscriber<PeriodStatistic | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.categories-per-period'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<PeriodStatistic>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportCategoriesPerPeriod(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.categories-per-period-download'));
  }

  sources(): Observable<SourcesStatistic | boolean> {
    return new Observable<SourcesStatistic | boolean>((o: Subscriber<SourcesStatistic | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.sources'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<SourcesStatistic>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportSources(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.sources-download'));
  }

  companies(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.companies'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportCompanies(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.companies-download'));
  }

  complaintsPerCompany(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.complaints-per-company'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportComplaintsPerCompany(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.complaints-per-company-download'));
  }

  complaintsPerCompanyAnonymous(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.complaints-per-company-anonymous'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportComplaintsPerCompanyAnonymous(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.complaints-per-company-anonymous-download'));
  }


  complaintsPerCompaniesPerMunicipality(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.complaints-per-companies-per-municipality'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportComplaintsPerCompaniesPerMunicipality(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.complaints-per-companies-per-municipality-download'));
  }

  complaintsPerCompaniesPerProvince(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.complaints-per-companies-per-province'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportComplaintsPerCompaniesPerProvince(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.complaints-per-companies-per-province-download'));
  }

  picketWeeklyOverview(): Observable<ServerResponse | boolean> {
    return new Observable<ServerResponse | boolean>((o: Subscriber<ServerResponse | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.picket-weekly-overview'))
        .subscribe((response: ServerResponse): void => {
          o.next(<ServerResponse>response);
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportPicketWeeklyOverview(): Observable<ServerResponse> {
    return this.api.get(this.apiEndpoints.get('statistics.picket-weekly-overview-download'));
  }

  solidDigitalLicenceReport(): Observable<SolidDigitalLicenceReport | boolean> {
    return new Observable<SolidDigitalLicenceReport | boolean>((o: Subscriber<SolidDigitalLicenceReport | boolean>) => {
      const subscription: Subscription = this.api.get(this.apiEndpoints.get('statistics.solid-digital-licence-report'))
        .subscribe((response: ServerResponse): void => {
          if (typeof response.data !== 'undefined') {
            o.next(<SolidDigitalLicenceReport>response.data);
          } else {
            o.next(false);
          }
        }, (): void => {
          o.next(false);
        });

      return (): void => {
        subscription.unsubscribe();
      };
    });
  }

  exportSolidDigitalLicenceReport(): Observable<ServerResponse> {
    return this.api.export(this.apiEndpoints.get('statistics.solid-digital-licence-report.download'));
  }
}

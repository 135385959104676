import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { User } from '../../interfaces/routering/user';

@Injectable()

export class AuthorizationService implements CanActivate, CanActivateChild {
  constructor(private router: Router,
              private titleService: Title,
              private authenticationService: AuthenticationService) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.user$.pipe(
      map((user: User): boolean => this.allowAccess(user, state, next))
    );
  }

  canActivateChild(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authenticationService.user$.pipe(
      map((user: User): boolean => this.allowAccess(user, state, next))
    );
  }

  allowAccess(user: User, state: RouterStateSnapshot, next: ActivatedRouteSnapshot): boolean {
    if (user) {
      const permissions: string[] = next.data['permissions'] as Array<string>,
        title: string = next.data['title'] + ' | ' + environment.webappName;

      if (user.role === 'super-admin') {
        this.titleService.setTitle(title);
        return true;
      }
      if (typeof permissions !== 'undefined') {
        if (permissions.length) {
          if (user.permissions && permissions.some((permission: string) => user.permissions.includes(permission))) {
            this.titleService.setTitle(title);
            return true;
          } else {
            this.router.navigate([`/handleiding`]).then((): void => {
            });
            return false;
          }
        } else {
          this.titleService.setTitle(title);
          return true;
        }
      } else {
        this.titleService.setTitle(title);
        return true;
      }
    } else {
      this.router.navigate([`/auth/login`], {queryParams: {returnUrl: state.url}}).then((): void => {
      });
      return false;
    }
  }
}
